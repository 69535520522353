@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/aos/src/sass/aos.scss";
@import "../../node_modules/intl-tel-input/build/css/intlTelInput.min.css";
@import "../../node_modules/simplelightbox/src/simple-lightbox.scss";
@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../../node_modules/animate.css/animate.min.css";

@font-face {
    font-family: "gilroyextrabold";
    src: url("../fonts/gilroy-extrabold-webfont.woff2") format("woff2"),
        url("../fonts/gilroy-extrabold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "gilroymedium";
    src: url("../fonts/gilroy-medium-webfont.woff2") format("woff2"),
        url("../fonts/gilroy-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "gilroybold";
    src: url("../fonts/gilroy-bold-webfont.woff2") format("woff2"),
        url("../fonts/gilroy-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "CircularStdMedium";
    src: url("../fonts/CircularStdMedium.eot");
    src: url("../fonts/CircularStdMedium.eot") format("embedded-opentype"),
        url("../fonts/CircularStdMedium.woff2") format("woff2"), url("../fonts/CircularStdMedium.woff") format("woff"),
        url("../fonts/CircularStdMedium.ttf") format("truetype"),
        url("../fonts/CircularStdMedium.svg#CircularStdMedium") format("svg");
}
@font-face {
    font-family: "CircularStd-Black";
    src: url("../fonts/CircularStd-Black.eot");
    src: url("../fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/CircularStd-Black.svg#CircularStd-Black") format("svg"),
        url("../fonts/CircularStd-Black.ttf") format("truetype"), url("../fonts/CircularStd-Black.woff") format("woff"),
        url("../fonts/CircularStd-Black.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "circular_stdregular";
    src: url("../fonts/circularstd-regular.woff2") format("woff2"),
        url("../fonts/circularstd-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.iti__flag {
    background-image: url("../../node_modules/intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../../node_modules/intl-tel-input/build/img/flags@2x.png");
    }
}
.iti__selected-flag{
    height: 42px;
}
.mt-o-5 {
    margin-top: 5rem;
}
.mt-o-10 {
    margin-top: 10rem;
}

.text-justify {
    text-align: justify;
}
.slick-slider {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "CircularStd-Black";
}
html,
body {
    overflow-x: hidden;
    font-family: "circular_stdregular";
}
.hv-100 {
    height: 100vh;
}
.navbar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.fixed-top {
    top: -40px;
    transform: translateY(40px);
    transition: all 0.3s;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.sticky-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.sticky-bar {
    ul.header-socials {
        li.list-inline-item {
            // &:not(:last-child) {
            //     margin-right: 1rem;
            // }
            a {
                color: #8998c2;
                &:hover {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }
    ul.vertical-border {
        li.list-inline-item {
            // &:not(:last-child) {
            //     margin-right: 1.5rem;
            // }
            a {
                position: relative;
                text-decoration: none;
                color: #8998c2;
                &:hover {
                    color: rgba(0, 0, 0, 0.9);
                    &::after {
                        bottom: -10px;
                        opacity: 1;
                    }
                }
                &::after {
                    opacity: 0;
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: -14px;
                    width: 7px;
                    height: 7px;
                    border-radius: 4px;
                    background-color: #6405d3;
                    margin-left: -3px;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}
.navbar-light {
    .wrapper-1 {
        .navbar-nav {
            a.nav-link {
                &::before {
                    content: "";
                    width: 0;
                    height: 2px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: #fff;
                    -webkit-transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                    -moz-transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                    -ms-transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                    -o-transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                    transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                }
            }
        }
    }
    .navbar-nav {
        li.nav-item {
            position: relative;
            // &:hover {
            //     a {
            //         &::before {
            //             width: 100%;
            //             left: 0;
            //         }
            //     }
            //     .btn-animated {
            //         &::before {
            //             width: 0;
            //         }
            //     }
            // }
            .dropdown-menu {
                .dropdown-item {
                    padding: 0.5rem 1rem;
                    &:active,
                    &.active {
                        background-color: #6405d3;
                    }
                }
            }
        }
        a.nav-link {
            color: #000000;
            text-transform: uppercase;
            font-family: "CircularStd-Black";
        }
    }
}
.animated-btn {
    position: relative;
    display: inline-block;
    .btn-animated {
        --c: #6405d3;
        color: var(--c);
        font-size: 16px;
        border: 0.1em solid var(--c);
        border-radius: 2em;
        width: 12em;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: 0.5s;
        display: inline-block;
        padding: 0.6rem 0.75rem;
        &::before {
            content: none;
        }
        &:hover {
            color: #fff !important;
        }
    }
    span {
        position: absolute;
        width: 25%;
        height: 100%;
        background-color: var(--c);
        transform: translateY(150%);
        border-radius: 50%;
        left: calc((var(--n) - 1) * 25%);
        transition: 0.5s;
        transition-delay: calc((var(--n) - 1) * 0.1s);
        z-index: -1;
    }
    &:hover {
        span {
            transform: translateY(0) scale(2);
        }
    }
    span:nth-child(1) {
        --n: 1;
    }
    span:nth-child(2) {
        --n: 2;
    }
    span:nth-child(3) {
        --n: 3;
    }
    span:nth-child(4) {
        --n: 4;
    }
}
.slider {
    .textarea {
        .main-title {
            line-height: 60px;
            letter-spacing: 0px;
            font-size: 50px;
            color: #fff;
            font-family: "CircularStd-Black";
        }
        .bannerSlider {
            flex: 0 0 auto;
            width: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
        }
        p {
            line-height: 28px;
            letter-spacing: 0px;
            font-weight: 400;
            font-size: 22px;
        }
        .btn {
            border-radius: 30px;
            background-color: #000;
            border-color: #000;
            color: #fff;
            padding: 0.8rem 2em;
            font-size: 20px;
            &:hover {
                opacity: 0.5;
            }
        }
    }
    .slick-arrow {
        &.slick-prev {
            left: 0;
            z-index: 1;
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f053";
            }
        }
        &.slick-next {
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f054";
            }
            right: 0;
        }
        cursor: pointer;
        min-width: 70px;
        min-height: 70px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: #0000008a;
        }
    }
}
.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 30px;
}

.glow-on-hover:before {
    content: "";
    background: linear-gradient(45deg, #ff0000, #ff7300, #ffcd1d, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 30px;
}

.glow-on-hover:active {
    color: #000;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 30px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.text-shadow {
    text-shadow: 0 0 5px #0000001a;
}

.master-heading {
    .divider {
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 4px;
            height: 4px;
            background-color: rgb(71, 50, 215);
            border-radius: 2px;
        }
        span {
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                width: 4px;
                height: 4px;
                background-color: rgb(71, 50, 215);
                border-radius: 2px;
            }
        }
    }
}
.textpara {
    .master-heading {
        margin-bottom: 85px;
    }
}
.master-heading {
    .divider {
        width: 37px;
        height: 4px;
        margin-bottom: 23px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        &::after {
            left: 11px;
        }
        span {
            &::before {
                left: 22px;
            }
            &::after {
                left: 33px;
            }
        }
    }
    .main-heading {
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 20px;
        font-family: "CircularStd-Black";
    }
    .sub-heading {
        font-size: 18px;
        line-height: 27px;
        color: #5e709d;
        font-family: "CircularStdMedium";
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #6405d3;
    margin: 0 0 20px;
    font-weight: 700;
}
.master-parallax-box {
    position: relative;
    width: 100%;
    .master-parallax-item {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        &.elementor-repeater-item-96f5992 {
            top: -35px;
            right: -30px;
            left: unset;
        }
        &.elementor-repeater-item-6e6054f {
            top: 330px;
            left: -65px;
        }
        &.elementor-repeater-item-0162773 {
            top: 575px;
            right: 40px;
            left: unset;
        }
        &.elementor-repeater-item-a9dc038 {
            box-shadow: rgba(41, 41, 43, 0.1) 0px 10px 35px 0px;
            border-radius: 20px;
            top: 0px;
            right: 20px;
            left: unset;
        }
        &.elementor-repeater-item-988ba69 {
            box-shadow: rgba(41, 41, 43, 0.1) 0px 10px 35px 0px;
            border-radius: 20px;
            top: 180px;
            left: 20px;
        }
        &.elementor-repeater-item-321bbb8 {
            box-shadow: rgba(41, 41, 43, 0.1) 0px 10px 35px 0px;
            border-radius: 20px;
            top: 365px;
            right: 70px;
            left: unset;
        }
        &.elementor-repeater-item-dde42e5 {
            top: 20px;
            left: -65px;
        }
        &.elementor-repeater-item-b821cc2 {
            top: 120px;
            right: 20px;
            left: unset;
        }
        &.elementor-repeater-item-915a90a {
            top: 0px;
            left: 0px;
            border-radius: 20px 20px 20px 20px;
            box-shadow: 0px 10px 35px 0px #29292b1a;
        }
        &.elementor-repeater-item-38cbe2e {
            top: 180px;
            right: 0px;
            left: unset;
            border-radius: 20px 20px 20px 20px;
            box-shadow: 0px 10px 35px 0px #29292b1a;
        }
        &.elementor-repeater-item-5235075 {
            top: 50px;
            right: 130px;
            left: unset;
        }
        &.elementor-repeater-item-f18c66d {
            top: 470px;
            right: 140px;
            left: unset;
        }
        &.elementor-repeater-item-c419b02 {
            top: 120px;
            right: 0px;
            left: unset;
            border-radius: 20px 20px 20px 20px;
            box-shadow: 0px 10px 35px 0px #29292b1a;
        }
        &.elementor-repeater-item-a1803e6 {
            top: 0px;
            left: 20px;
            border-radius: 20px 20px 20px 20px;
            box-shadow: 0px 10px 35px 0px #29292b1a;
        }
    }
}
.parallax-wrap {
    height: 550px;
}
.master-wrap {
    padding: 0 100px;
    .master-heading {
        &:nth-of-type(3) {
            .main-heading {
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 42px;
            }
        }
        .main-heading {
            margin-bottom: 36px;
            font-size: 28px;
            line-height: 32px;
        }
        .sub-heading {
            margin-bottom: 40px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
.figure-caption {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
span.theme-color {
    color: #231f20;
}
.theme-color {
    color: #7f00ff;
}
.sec2 {
    .nav-tabs {
        .nav-link {
            text-transform: uppercase;
            color: #000;
            font-size: 15px;
            margin-right: 5px;
            &:focus-visible {
                outline: none;
            }
        }
    }
    .card.icon-box {
        &:hover {
            background-color: #4732d7;
            -webkit-transition: background-color 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            -moz-transition: background-color 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            -ms-transition: background-color 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            -o-transition: background-color 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            transition: background-color 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            .card-body {
                img {
                    filter: brightness(0) invert(1);
                }
                .figure-caption {
                    color: #fff;
                }
            }
        }
    }
    .price-wrap {
        text-align: center;
        ul.nav-tabs {
            border: 0;
            display: inline-flex;
            background-color: #fff;
            box-shadow: 0 0px 10px 0 #0000002b;
            padding: 5px;
            border-radius: 50px;
        }
        li {
            .nav-link {
                padding: 0.5rem 2rem;
                outline: 0;
                border: 0;
                border-radius: 30px;
                color: #6405d3;
                &.active {
                    background-color: #1c1624;
                    color: #fff;
                    padding: 0.5rem 2rem;
                }
                &:hover {
                    border: 0;
                }
            }
        }
    }
    .tab-content {
        &#myTabContent {
            .slick-prev,
            .slick-next {
                top: -40px;
            }
            .slick-prev {
                left: unset;
                right: 50px;
                &::before {
                    color: #7f00ff;
                    opacity: 1;
                    font-size: 25px;
                }
            }
            .slick-next {
                right: 20px;
                &::before {
                    color: #7f00ff;
                    opacity: 1;
                    font-size: 25px;
                }
            }
        }
        .multiple-items {
            .slick-slide {
                padding: 0 20px;
                .card {
                    border-radius: 20px;
                    border-top: 0;
                    .card-header {
                        padding: 50px 40px;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        position: relative;
                        background-color: #7f00ff;
                        color: #fff;
                        text-align: center;
                        .package-header {
                            ul {
                                li {
                                    &:nth-child(1) {
                                        font-size: 1.2rem;
                                        font-weight: 500;
                                    }
                                }
                                li {
                                    &:nth-child(2) {
                                        font-size: 2.4rem;
                                        font-weight: 500;
                                    }
                                }
                                li {
                                    &:nth-child(3) {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        .card-header {
                            background-color: #1c1624;
                            .discount-img {
                                background: url(../img/active-discount.webp) no-repeat center center / contain;
                            }
                        }
                    }
                    .discount-img {
                        position: absolute;
                        width: 30%;
                        bottom: -50px;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        height: 117px;
                        background: url(../img/deactive-discount.webp) no-repeat center center / contain;
                    }
                    .card-body {
                        ul {
                            min-height: 350px;
                            height: 400px;
                            overflow-y: scroll;
                            li {
                                margin: 0.5rem 0;
                                font-size: 0.9rem;
                                display: flex;
                                i{
                                    color: #0a58ca;
                                    font-size: 20px;
                                }
                            }
                        }
                        .order-button {
                            border: 1px solid #dadae5;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 2em 0;
                            border-radius: 10px;
                            .btn {
                                padding: 0.6rem 0.75rem;
                            }
                        }
                        .package-addon {
                            font-family: "circular std medium";
                            font-size: 0.8rem;
                            color: #2d2836;
                            display: flex;
                            background-color: #fff;
                            padding: 0.5em;
                            align-items: center;
                            justify-content: center;
                            width: 80%;
                            margin: auto;
                            margin-bottom: -1.4em;
                            position: relative;
                            span {
                                &.theme-color {
                                    color: #7f00ff;
                                }
                            }
                        }
                        .package-contact-num {
                            a {
                                color: #6405d3;
                                &:hover {
                                    color: #7f00ff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.sec3 {
    #accordionFlushExample {
        .add-theme-color {
            .accordion-button {
                color: #6405d3;
            }
        }
        .accordion-button {
            font-size: 1.4rem;
            &::after {
                content: none;
            }
            &:not(.collapsed) {
                color: #6405d3;
            }
        }
        .accordion-item {
            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
    }
    .animated-btn {
        .btn-animated {
            width: 15em;
        }
    }
    .img-arrangement {
        img:nth-child(2) {
            position: absolute;
            top: 100px;
            left: 100px;
            max-width: 35%;
        }
        img:nth-child(3) {
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: 50%;
        }
    }
    .accordion-flush {
        position: relative;
        .accordion-item {
            padding: 10px 0;
            &:first-child {
                padding-top: 0;
            }
            &::after {
                content: "";
                background-color: #bebebe;
                width: 7px;
                height: 7px;
                position: absolute;
                border-radius: 50px;
                left: 0;
                margin-top: 7px;
            }
        }
    }
}
.rounded-20 {
    border-radius: 20px 20px 20px 20px;
}

.masonry {
    .card {
        .hover-style {
            display: block;
            position: relative;
            overflow: hidden;
            border-radius: 20px 20px 20px 20px;

            &:hover::before {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
            &:hover::after {
                opacity: 1;
            }
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(41, 41, 43, 0.7);
                content: "";
                opacity: 0;
                transition: opacity 0.35s;
                z-index: 1;
            }
            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -22px 0 0 -22px;
                display: inline-block;
                width: 44px;
                height: 44px;
                background-color: #fff;
                font-size: 20px;
                color: #7d8dff;
                border-radius: 22px;
                line-height: 42px;
                text-align: center;
                content: "+";
                z-index: 2;
                transition: opacity 0.35s, transform 0.35s;
                transform: translate3d(0, 100%, 0);
                opacity: 0;
            }
        }
    }
}
.sec4 {
    &.bgGrayTouch {
        .masonry {
            .card {
                background-color: transparent;
                .hover-style {
                    border-radius: 20px 20px 0px 0px;
                }
                .card-body {
                    border-radius: 0 0 20px 20px;
                    background-color: #fff;
                }
            }
        }
    }
}
.sec5 {
    background: url("../img/design-pattern.svg") #1a1423 no-repeat 125% 0;
    .animated-area {
        margin: 5rem 0;
        h1 {
            font-size: 3rem;
        }
        .master-heading {
            .main-heading {
                font-weight: 700;
                font-size: 50px;
                line-height: 1.4;
            }
            .divider {
                &::before,
                &::after {
                    background-color: #fff;
                }
                span {
                    &::before,
                    &::after {
                        background-color: #fff;
                    }
                }
            }
        }
        .item-animate {
            img {
                height: 80px;
            }
        }
    }
}
.sec6 {
    margin-bottom: 80px;
    .first {
        .hr {
            &::after {
                right: 10px;
            }
        }
    }
    .hr {
        &::after {
            content: "";
            background-color: #212529;
            width: 7px;
            height: 7px;
            position: absolute;
            border-radius: 50px;
            margin-top: -3px;
        }
    }
    .s-item {
        border-left: 1px solid #d6d6e8;
        border-bottom: 1px solid #d6d6e8;
        padding-left: 1em;
        padding-bottom: 1em;
        position: relative;
        margin-top: 3em;
        legend {
            margin-top: -15px;
            margin-left: 30px;
        }
        .d-flex {
            padding: 0.5em;
            &::after {
                content: "";
                width: 7px;
                height: 7px;
                position: absolute;
                background: #d6d6e8;
                right: -3px;
                border-radius: 50px;
                bottom: 30px;
            }
        }
        &::before {
            content: "";
            width: 34px;
            height: 1px;
            position: absolute;
            background: #d6d6e8;
            left: 0;
        }
        &::after {
            content: "";
            width: 1px;
            height: 34px;
            position: absolute;
            background: #d6d6e8;
            right: 0;
            bottom: 0;
        }
    }
}
.s-item {
    .d-flex {
        .flex-grow-1 {
            &.height-scroll {
                height: 100px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0.2em;
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    outline: 1px solid slategrey;
                }
            }
        }
    }
}
.sec7 {
    .master-heading {
        margin: 5rem 0;
    }
    .testimonial {
        .slick-list {
            margin-bottom: 80px;
            .slick-slide {
                &.slick-current {
                    &.slick-active {
                        .card {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
        .slick-dots {
            bottom: -50px;
            li {
                button {
                    &::before {
                        font-size: 12px;
                        color: #4732d7;
                    }
                }
            }
        }
        .card {
            padding: 40px;
            border-radius: 20px 20px 20px 20px;
            height: 345px;
            .avatar {
                width: 70px;
                height: 70px;
                margin: 0 auto;
                margin-bottom: 17px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #6405d3;
                h3 {
                    color: #fff;
                    margin: 0;
                    line-height: 2.5;
                }
            }
            .info-wrap {
                .name {
                    font-size: 16px;
                    margin-bottom: 2px;
                    color: #6405d3;
                }
                .role {
                    font-size: 15px;
                    color: #aeaeae;
                    margin-bottom: 6px;
                }
            }
            .comment {
                font-size: 16px;
                line-height: 24px;
                margin-top: 23px;
                color: #5e709d;
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.sec8 {
    margin: 4rem 0 3rem;
    background: url("../img/subscribe.png") no-repeat center center / contain;
    form {
        input {
            border-radius: 30px;
            background-color: #f1f6fa;
            padding: 0.6rem 0.75rem 0.6rem 1rem;
            //width: 300px;
            &:focus-visible,
            &:focus,
            &:active {
                outline: 0;
                border-radius: 30px;
                border: 0;
                background-color: #daebfb;
            }
        }
        textarea {
            background-color: #f1f6fa;
        }
    }
}
.footer {
    background-color: #1a1423;
    color: #cacaca;
    padding: 25px 0 25px;
    position: relative;
    .widget-title {
        font-size: 16px;
        margin: 18px 0 36px;
        color: #fff;
    }

    .widget-links {
        li {
            &:hover {
                a {
                    &::after {
                        width: 100%;
                        left: 0;
                    }
                }
            }
            a {
                position: relative;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                transition: all ease 0.3s;
                padding-bottom: 2px;
                color: #cacaca;
                &::after {
                    content: "";
                    width: 0;
                    height: 2px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background-color: #4732d7;
                    transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
                }
                &:hover {
                    color: #8998c2;
                }
            }
        }
    }
    ul {
        &.social-icons {
            li {
                .special-margin {
                    margin-right: 10px;
                }
                margin-bottom: 0.5rem;
                display: flex;
            }
        }
        li {
            color: #cacaca;
            a {
                text-decoration: none;
                color: #cacaca;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
.bottom {
    font-size: 15px;
    color: #aeaeae;
    background-color: #1a1423;
    position: relative;
    padding: 10px 0;
    border-top: 1px solid #232649;
    .condition {
        float: right;
        margin-right: 100px;
        a {
            position: relative;
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            transition: all ease 0.3s;
            padding-bottom: 2px;
            color: #cacaca;
            &:hover {
                color: #fff;
            }
            &::after {
                content: "";
                width: 0;
                height: 2px;
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: #4732d7;
                transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
            }
        }
        li {
            &:hover {
                a {
                    &::after {
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}
.discount-form {
    .form_upper {
        h3 {
            color: #2d2836;
            font-size: 0.875rem;
            letter-spacing: 3px;
            text-transform: uppercase;
            padding: 1.5em 1.5em 1em;
            margin-bottom: 0;
            text-align: center;
        }

        h1 {
            font-size: 1.75rem;
            color: #181818;
            line-height: 1;
            text-align: center;
            font-weight: 700;
            margin-bottom: 0;
        }
    }
    .form-divider {
        .left {
            &::before {
                content: "";
                background-color: #212529;
                width: 7px;
                height: 7px;
                position: absolute;
                border-radius: 50px;
                margin-top: -3px;
                right: 0;
            }
        }
        .right {
            &::before {
                content: "";
                background-color: #212529;
                width: 7px;
                height: 7px;
                position: absolute;
                border-radius: 50px;
                margin-top: -3px;
            }
        }
    }
    .d-grid {
        .btn {
            background-color: #6405d3;
            color: #fff;
            padding: 0.6rem 0.75rem;
        }
    }
    .form-row {
        margin-bottom: 0.8rem;
    }
    .form-control-lg {
        min-height: inherit;
        font-size: 1rem;
    }
    .iti {
        display: block;
    }
}
.inner-banner {
    padding: 2em;
    // min-height: 680px;
    .header-text {
        // padding: 195px 0;
        padding: 125px 0;
        h1 {
            font-family: "CircularStd-Black";
            color: #fff;
            font-size: 3.375rem;
        }
        p {
            color: #fff;
            font-family: "CircularStdMedium";
            font-size: 1.3rem;
        }
        .divider {
            border: 1px solid #fff;
            width: 10%;
        }
    }
}
.web-sec2 {
    background-color: #1a1423;
    .textpara {
        padding-top: 85px;
        .master-heading {
            .sub-heading,
            .main-heading {
                color: #fff;
            }
            .divider {
                &::before,
                &::after {
                    background-color: #fff;
                }
                span {
                    &::before,
                    &::after {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .category {
        .nav-tabs {
            border-bottom: 0;
        }
        .nav-link {
            border: 0;
            color: #fff;
            border-radius: 0;
            padding: 1em;
            &.active {
                background-color: #6405d3;
                position: relative;
                &::after {
                    content: "";
                    border-top: 1.3em solid #6405d3;
                    border-right: 1em solid transparent;
                    border-bottom: 0;
                    border-left: 1em solid transparent;
                    position: absolute;
                    bottom: -20px;
                }
            }
            img {
                margin-bottom: 1em;
            }
        }
        h2 {
            color: #fff;
            font-family: "CircularStd-Black";
            height: 70px;
        }
        .tab-content {
            margin-top: 50px;
            .card {
                background-color: #f7f7f7;
                .card-body {
                    padding: 3em;
                    p {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}
.web-sec5 {
    background-color: #1a1423;
    position: relative;
    margin-bottom: 150px;
    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 90px;
        background: url("../img/checkmate/wave.png");
        background-repeat: no-repeat;
        background-position: center;
    }
    &.logo-process {
        text-align: justify;
    }
    .textpara {
        margin-top: 85px;
        .master-heading {
            .sub-heading,
            .main-heading {
                color: #fff;
            }
            .divider {
                &::before,
                &::after {
                    background-color: #fff;
                }
                span {
                    &::before,
                    &::after {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .card {
        &.bg-transparent {
            position: relative;
            &::before {
                content: "";
                border-right: 2px solid #332e3b;
                position: absolute;
                right: 0;
                width: 2px;
                height: 300px;
            }
            &.last {
                &::before {
                    content: none;
                }
            }
            h3 {
                color: #fff;
                font-family: "CircularStd-Black";
                font-size: 1.5rem;
                margin-top: 20px;
            }
            p {
                color: #fff;
                font-family: "CircularStdMedium";
            }
        }
    }
}
.web-sec6 {
    position: relative;
    margin-top: 200px;
    .cta-wraper {
        //position: absolute;
        //top: 0;
        //width: 100%;

        background: url("../img/cta-bg.jpg") no-repeat center / cover;
        .cta-para {
            display: flex;
            flex-flow: column;
            justify-content: center;
            height: 100%;
            .cta-btn {
                width: 200px;
                background-color: #6405d3;
                color: #fff;
                text-decoration: none;
                text-align: center;
                padding: 0.6rem 0.75rem;
                border-radius: 30px;
                border: 1px solid transparent;
                &:hover {
                    background-color: transparent;
                    border: 1px solid #6405d3;
                }
            }
        }
        img {
            margin-top: -103px;
        }
    }
}
.modal {
    &.modal-right {
        &.fade {
            .modal-dialog {
                right: -500px;
                -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
                -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
                -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                transition: opacity 0.3s linear, right 0.3s ease-out;
                -webkit-transform: translate3d(0%, 0, 0);
                -ms-transform: translate3d(0%, 0, 0);
                -o-transform: translate3d(0%, 0, 0);
                transform: translate3d(0%, 0, 0);
                position: fixed;
                margin: auto;
                width: 50%;
                max-width: 100%;
                height: 100%;
            }
            &.show {
                .modal-dialog {
                    right: 0;
                }
            }
        }
        .modal-content {
            border-radius: 0;
            border: none;
            background-color: #6405d3;
            background: url("../img/formBg.jpg") no-repeat;
            height: 100%;
            // overflow-y: scroll;
            .btn-close {
                background-size: 20px;
                background-color: #fff;
                width: 2em;
                height: 2em;
                opacity: 1;
                position: absolute;
                border-radius: 0;
                z-index: 2;
                right: 0;
            }
        }
        .modal-body {
            padding: 25px 50px;
            display: flex;
            align-items: center;
            .master-heading {
                .main-heading {
                    font-size: 2.5rem;
                    color: #fff;
                    line-height: 45px;
                    margin-bottom: 0.5rem;
                }
                .divider {
                    margin-left: 0;
                    margin-bottom: 1rem;
                    &::before,
                    &::after {
                        background-color: #ffffff;
                    }
                    span {
                        &::before,
                        &::after {
                            background-color: #ffffff;
                        }
                    }
                }
                .sub-heading {
                    color: #fff;
                    font-size: 20px;
                }
            }
        }
    }
}
.sticky-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -105px;
    margin: auto;
    width: 263px !important;
    height: 53px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0;
    background-color: #6405d3;
    border-color: #6008c6;
    z-index: 99;
    &:hover,
    &:active,
    &:focus {
        background-color: #2a005b;
        border-color: #6008c6;
    }
}
.iti {
    display: block;
}
.form-control:focus {
    box-shadow: none;
}

.services-tab {
    .nav-pills {
        .nav-link {
            &.active {
                background-color: #fff;
                color: #000000;
                border-color: #6405d3;
                &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: #6405d3;
                    position: absolute;
                    border-radius: 50px;
                    right: 0;
                    margin: -5px;
                    top: 50%;
                }
            }
            text-align: left;
            background-color: transparent;
            border: 2px solid #fff;
            border-radius: 0;
            margin: 0.3em 0;
            color: #fff;
            position: relative;
        }
    }
    .tab-content {
        .card {
            background-color: transparent;
            font-family: "CircularStdMedium";
            .card-body {
                padding: 0 1rem;
                color: #fff;
                h3,
                h5 {
                    color: #fff;
                }
                p {
                    color: #ddd;
                    text-align: justify;
                    width: 80%;
                }
                ul {
                    li {
                        margin: 0.3rem 0;
                        align-items: center;
                        display: flex;
                    }
                }
            }
        }
    }
}
.E-commerce-sec2 {
    padding-bottom: 200px;
}
.E-commerce-sec3 {
    // background: url("../img/laptop-bg.jpg") no-repeat top center / cover;
    .textpara {
        font-family: "CircularStd-Black";
        position: relative;
        small {
            text-transform: uppercase;
            color: #6405d3;
            &::before {
                content: "";
                width: 90px;
                height: 2px;
                background-color: #7f00ff;
                position: absolute;
                left: -100px;
                top: 10px;
            }
        }
        h3 {
            color: #000;
            margin-top: 15px;
            font-size: 1.5rem;
        }
    }
    .laptop-wrape {
        margin-top: -150px;
    }
}

.laptop-wrape {
    .laptopBg {
        position: relative;
        width: 100%;
        height: auto;
        .imgBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;
        }
    }
}

@mixin rsOverflow($size) {
    width: 100%;
    height: $size;
    position: relative;
    overflow: hidden;
    float: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .rsContainer {
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        .rsSlide {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            overflow: hidden;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}
#slider-in-laptop {
    height: 350px;
    padding: 2% 15% 7%;
    margin-bottom: 50px;
    background: none;
    .rsOverflow {
        @include rsOverflow(290px);
    }
}

#slider-in-mobile {
    height: 520px;
    padding: 5% 4% 0;
    background: none;
    .rsOverflow {
        @include rsOverflow(520px);
    }
}

.E-commerce-sec6 {
    background-color: #1a1423;
    color: #fff;
    padding-bottom: 80px;
    &.sec6 {
        margin-bottom: 3rem;
    }
    .master-heading {
        .main-heading {
            color: #fff;
        }
        .sub-heading {
            color: #fff;
        }
        .divider {
            filter: brightness(0) invert(1);
        }
    }
    .s-item {
        img {
            filter: brightness(0) invert(1);
        }
    }
}
.E-commerce-sec8 {
    margin: 100px 0 0;
}
.E-commerce-sec9 {
    &.web-sec6 {
        margin-top: 150px;
    }
}
form {
    .form-floating {
        label {
            padding: 1rem 2em;
        }
        textarea {
            &.form-control {
                height: calc(7rem + 2px);
            }
        }
    }
    .animated-btn {
        button {
            height: calc(3.5rem + 2px);
        }
    }
}
.about-us-banner,
.packages-banner,
.portfolio-banner,
.contact-us-banner {
    height: 400px;
    display: flex;
    align-items: center;
}
.about-us-banner {
    background: url("../img/AboutUsBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.packages-banner {
    background: url("../img/packages.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.contact-us-banner {
    background: url("../img/contact-us.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.ecommerce-website-design-banner {
    background: url("../img/EcommerceBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.portfolio-banner {
    background: url("../img/PortfolioBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.web-design-banner {
    background: url("../img/WebDesignBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.mobile-app-banner {
    background: url("../img/AppBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.logo-app-banner {
    background: url("../img/LogoBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.seo-design-banner {
    background: url("../img/SEOBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.digital-design-banner {
    background: url("../img/SocialMediaBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.video-design-banner {
    background: url("../img/AnimationBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.packaging-design-banner {
    background: url("../img/PackagingBanner.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.privacy-banner {
    background: url("../img/Privacypolicy.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.term-condition-banner {
    background: url("../img/TermsCondition.webp") no-repeat center 20% / cover;
    margin-top: 58px;
}
.about-sec {
    margin: 90px 0;
}
.about-us-sec-2 {
    background: url("../img/space-bg.webp") no-repeat center 20% / cover;
    height: 68em;
    padding-top: 5em;
    .master-heading {
        .sub-heading {
            color: #ffffff;
            margin-bottom: 10px;
            &::before {
                content: "";
                background-color: #fff;
                width: 100px;
                height: 2px;
                position: absolute;
                left: 0;
                margin-left: -110px;
                margin-top: 10px;
            }
        }
        .main-heading {
            color: #fff;
            font-size: 2.875em;
            font-weight: 700;
            line-height: initial;
        }
    }
    &.marketing-process-sec {
        position: relative;
        &.culture-innovation {
            .tab-sec {
                border-right: none;
                margin-top: 142px;
                height: auto;
                position: relative;
                .nav-link {
                    width: 295px;
                    height: 294px;
                    border: solid 1px #ffffff;
                    border-radius: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    padding-top: 30px;
                    color: #fff;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 17px;
                    transition: 0.5s all;
                    cursor: pointer;

                    &:nth-child(1) {
                        left: 125px;
                        top: 0;
                        span {
                            margin: -125px 0 0 0;
                            display: block;
                        }
                    }
                    &:nth-child(2) {
                        left: 0;
                        top: 75px;
                        span {
                            float: left;
                            margin: -80px 0 0 25px;
                        }
                    }
                    &:nth-child(3) {
                        left: auto;
                        top: 75px;
                        right: 0;
                        span {
                            float: right;
                            margin: -80px 25px 0 0;
                        }
                    }
                    &:nth-child(4) {
                        left: 0;
                        top: 220px;
                        span {
                            float: left;
                            margin: 30px 0 0 25px;
                        }
                    }
                    &:nth-child(5) {
                        left: auto;
                        top: 220px;
                        right: 0;
                        span {
                            float: right;
                            margin: 30px 25px 0 0;
                        }
                    }
                    &:nth-child(6) {
                        left: 125px;
                        top: 294px;
                        span {
                            margin: 150px 0 0 0;
                            display: block;
                        }
                    }
                    &.active,
                    &:hover {
                        border-color: #6405d3;
                        color: #6405d3;
                        background: transparent;
                        transition: 0.5s all;
                    }
                }
            }
            .tab-content {
                margin-top: 142px;
                position: relative;
                .tb-text {
                    .number {
                        color: #fff;
                        font-size: 24px;
                    }
                    .title {
                        font-size: 72px;
                        color: #fff;
                    }
                    .description {
                        color: #fff;
                        font-size: 24px;
                        text-align: justify;

                        // &::before {
                        //     content: "";
                        //     background-color: #fff;
                        //     width: 85px;
                        //     height: 2px;
                        //     position: absolute;
                        //     left: 0;
                        //     margin-left: -80px;
                        //     margin-top: 20px;
                        // }
                    }
                }
            }
        }
    }
}
.contact-detail-sec {
    background: url("../img/cta-bg.jpg") no-repeat center center / cover;
    color: #fff;
    padding: 50px 0;
    h3,
    h5 {
        color: #fff;
    }
}
.packages-sec {
    background: url("../img/combo-bg.jpg") no-repeat;
    margin: 0 0 30px;
    padding: 100px 0;
    nav {
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #dee2e6;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        .nav-tabs {
            border-bottom: 0;
            .nav-link {
                padding: 0.6rem 1.1rem;
                border-radius: 0;
                font-weight: 500;
                color: #000;

                &.active {
                    border-top-color: #6405d3;
                    color: #6405d3;
                }
                &:focus-visible {
                    outline: 0;
                }
            }
            .nav-item {
                &.show {
                    .nav-link {
                        border-top-color: #6405d3;
                    }
                }
            }
        }
    }
    hr {
        background-color: #fff;
        opacity: 1;
    }
    .tab-content {
        margin-top: 60px;
    }
    .extra-space {
        .card {
            background: url("../img/combo-bg.jpg") no-repeat center center / cover;
            box-shadow: 1px 1px 17px 0 #6108c0;
            border: 2px solid #6108c0;
            color: #fff;
            .card-body {
                .card-header {
                    border-color: #fff;
                    p {
                        color: #fff;
                        margin-bottom: 0;
                        font-size: 1.5rem;
                        font-weight: 700;
                    }
                    .combo_pack_doller {
                        p {
                            font-size: 2.8rem;
                            font-weight: 700;
                        }
                    }
                }
                .combo-price-detail {
                    padding: 0.5rem 1rem;
                }
                .listing {
                    ul {
                        li {
                            margin-bottom: 0.6rem;
                            i{
                                font-size: 20px;
                            }
                        }
                    }
                }
                .card-footer {
                    border-color: #fff;
                    .animated-btn {
                        .btn {
                            background: #fff;
                            color: #000;
                            font-weight: 700;
                            text-decoration: none;
                            display: block;
                            padding: 0.55em 6em;
                            border-radius: 30px;
                        }
                    }
                }
            }
        }
        .master-heading {
            h2,
            .sub-heading {
                color: #fff;
            }
            .main-heading {
                &.tag-line {
                    font-size: 2.5rem;
                    line-height: 1.2;
                }
            }
        }
    }
}
.portfolio-sec {
    margin: 150px 0 0;
    .master-heading {
        .sub-heading {
            color: #212529;
            font-size: 1rem;
            &.subtitle {
                font-size: 15px;
                color: #6108c0;
                line-height: 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
                position: relative;
                margin-bottom: 20px;
                &::before {
                    content: "";
                    background-color: #6108c0;
                    width: 80px;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    margin: 8px 0 0 -90px;
                }
            }
        }
        .main-heading {
            line-height: 85px;
            font-size: 95px;
            margin-bottom: 20px;
            color: #212529;
        }
    }
    .vid_inner1 {
        width: 100%;
        max-height: 300px;
    }
}
.portfolio-filter-sec {
    .button-group {
        margin-bottom: 25px;
        .btn {
            width: 200px;
            text-transform: uppercase;
            color: #212529;
            border-color: #7f00ff;
            margin: 0 10px 10px 0;
            &.is-checked,
            &:hover,
            &:active,
            &:focus {
                background-color: #7f00ff;
                border-color: #7f00ff;
                color: #fff;
                box-shadow: none;
            }
        }
    }
}
.form-control-lg {
    font-size: 1rem;
}
.contact-us-sec {
    background: url("../img/bg-patteran.png") no-repeat right top,
        url("../img/bg-patteran-bottom.png") no-repeat left bottom, #1b1523;

    .cotnact-form-sec {
        padding: 50px;
        border-radius: 15px;
        margin-bottom: 80px;
        .card-header {
            background-color: transparent;
            border-bottom: 0;
        }
    }
}
.btn-theme {
    background-color: #6405d3;
    border-color: #6405d3;
    color: #fff;
}
.contact-us-sec-2 {
    margin: 80px 0;
    .master-heading {
        margin-bottom: 50px;
    }
    .card {
        border-radius: 20px;
        padding: 50px 50px 40px 50px;
        height: 300px;
        // &.elementor-widget-container {
        //     padding: 132px 50px 84px 50px;
        //     background: url("../img/img-11.jpg") no-repeat center / cover;
        //     .master-icon-box {
        //         .desc {
        //             color: #6405d3;
        //             font-weight: 600;
        //         }
        //     }
        // }
        i {
            font-size: 60px;
        }
    }
}
.loader,
.succefullyMessage {
    display: none;
}
.error {
    color: red;
}
.web-sec3 {
    &.portfolio-filter-sec {
        .portfoliogrid {
            margin-bottom: 100px;
        }
    }
}
.footer-logo {
    filter: brightness(0) invert(1);
}
.mobile-app-sec {
    padding-bottom: 100px;
}
.sl-overlay {
    background: #909090;
}

.motionGraphics {
    .hover-style-video {
        .mklbItem {
            &:hover {
                opacity: 0.5;
                filter: alpha(opacity=30);
                cursor: pointer;
                font-style: italic;
            }
        }
    }
}
#mkLightboxContainer {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    // z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

#mkLightboxContainer > * {
    z-index: 99;
}

#overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.85);
    z-index: 0;
}

#mkLightboxContainer #mklbInner {
    position: relative;
    height: 100vh;
    transition: all 0.4s ease-in-out;
    margin-left: 0;
}
#mkLightboxContainer img,
#mkLightboxContainer video {
    background: #fff;
    box-shadow: 0 0 30px #222;
    max-height: 85vh;
    min-width: auto;
    max-width: 45vw;
    width: auto;
    height: auto;
}

#mkLightboxContainer #yt-video {
    width: 45vw;
    height: 42.1875vw; /* 75*9/16 */
}

#closeIconContainer {
    width: 25px;
    height: 25px;
    position: fixed;
    top: 100px;
    right: 35px;
    transition: transform 0.2s linear;
}

#closeIconContainer:hover {
    cursor: pointer;
    transform: rotate(90deg);
}

#closeIconContainer svg,
#prev svg,
#next svg,
#stopGallery svg,
#playGallery svg {
    fill: white;
}

#prevContainer,
#nextContainer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 10px 5px 10px 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
}

#nextContainer {
    left: auto;
    right: 0;
}

#prevContainer:hover,
#nextContainer:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.25);
}

#prev,
#next {
    width: 35px;
    height: 35px;
}
#controlContainer {
    position: absolute;
    top: 1em;
    left: 0.5em;
    display: flex;
}

#controlContainer > div {
    width: 2em;
}

#controlContainer > div:hover {
    cursor: pointer;
}

#controlContainer #stopGallery,
#controlContainer.stop #playGallery {
    opacity: 0.5;
}

#controlContainer.stop #stopGallery,
#controlContainer #playGallery {
    opacity: 1;
}
.leadgentbtn {
    font-family: CircularStdMedium;
    li {
        .button {
            width: 175px;
            border-radius: 50px;
            padding: 7px 25px;
            border: 0;
            outline: none;
            text-transform: uppercase;
            &:focus,
            &:active {
                box-shadow: none;
                outline: none;
            }
            .bi-emoji-smile {
                color: #ffcd1d;
                margin-top: -2px;
                &::before {
                    transform: rotate(360deg);
                    transition: transform 0.7s ease-in-out;
                }
            }
            &:hover {
                .bi-emoji-smile {
                    &::before {
                        transform: rotate(180deg);
                        transition: transform 0.7s ease-in-out;
                    }
                }
            }
            i {
                font-size: 20px;
            }
        }
        &:first-child {
            .button {
                background-color: #000;
            }
        }
        &:last-child {
            .button {
                background-color: #6405d3;
            }
        }
    }
}

.slider-banner-1 {
    background-color: #549e64;
    background: #549e64 no-repeat 60% 50% / cover url("../img/actionslider1.webp");
}

.slider-banner-2 {
    background-color: #8a5ff2;
    background: #8a5ff2 no-repeat 60% 50% / cover url("../img/actionslider2.webp");
}
.slider-banner-3 {
    background: #fff no-repeat 60% 50% / cover url("../img/actionslider3.webp");
    &.textarea {
        .main-title {
            color: #6406d4;
        }
    }
}
.slider-banner {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }
}
.homeSlider {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    &.slick-initialized {
        visibility: visible;
        opacity: 1;
    }
    .slick-dots {
        bottom: 20px;
        li {
            height: 30px;
            width: 30px;
            button {
                height: 30px;
                width: 30px;
                &::before {
                    width: 28px;
                    height: 30px;
                    line-height: 5;
                    color: #000;
                    opacity: 1;
                }
            }
            &.slick-active {
                border: 1px solid #d9af0b;
                border-radius: 50px;
                button {
                    &::before {
                        color: #d9af0b;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .slider-margin {
        margin-left: 15px;
    }
    .homeslideinner {
        height: calc(100vh - 120px);
        position: relative;
    }
}
.sec1 {
    .yellow-box {
        background-color: #d38f0b;
        &::before {
            content: "";
            width: 270px;
            height: 270px;
            position: absolute;
            border-radius: 150px;
            background: rgb(218, 158, 43);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            right: -140px;
            top: -130px;
        }
        .card-header {
            h3 {
                color: #da9e2b;
            }
        }
    }
    .red-box {
        background-color: #d20052;
        &::before {
            content: "";
            width: 500px;
            height: 535px;
            position: absolute;
            border-radius: 300px;
            background: rgba(255, 0, 137, 0.3);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            left: -230px;
            top: -100px;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .card-header {
            h3 {
                color: #d20052;
            }
        }
    }
    .purpule-box {
        background-color: #8132cf;
        &::before {
            content: "";
            width: 400px;
            height: 500px;
            position: absolute;
            border-radius: 0;
            background: #670cc1;
            transform: matrix(0, 1, 1, 0, 0, 0);
            bottom: -250px;
            border-bottom-left-radius: 500px;
        }
        .card-header {
            h3 {
                color: #8132cf;
            }
        }
    }
    .rightSideBox {
        .card-box {
            margin: 75px 0 65px;
        }
    }
    .card-box {
        overflow: hidden;
        .card-body {
            z-index: 1;
        }
        .card-header {
            background-color: transparent;
            margin: 25px 0 50px;
            border-bottom: 0;
            h3 {
                background-color: #fff;
                display: inline-block;
                padding: 5px;
                text-transform: uppercase;
            }
            p {
                font-family: "CircularStdMedium";
                color: #fff;
                font-size: 40px;
                line-height: 1.3;
                padding-right: 30px;
            }
            .divider {
                span {
                    &::before,
                    &::after {
                        background-color: #fff;
                    }
                }
                &::before,
                &::after {
                    background-color: #fff;
                }
            }
        }
        .card-footer {
            background-color: transparent;
            border-top: 0;
            ul {
                &.mainTitle {
                    margin: 0 0 0.5rem;
                }
                margin: 0;
                padding: 0;
                color: #fff;
                font-family: "CircularStdMedium";
                li {
                    margin-right: 15px;
                    &::after {
                        content: "";
                        background-color: #fff;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        border-radius: 50px;
                        margin: 8px 0 0 5px;
                    }
                    &:last-child {
                        &::after {
                            width: 0;
                        }
                    }
                }
            }
        }
    }
    .master-heading {
        .sub-heading {
            text-align: justify;
        }
    }
}
.projectSliderWrapper {
    margin-left: 100px;
    .swiper-slide {
        width: 20rem;
        .card {
            font-family: "CircularStdMedium";
            .card-body {
                .card-title {
                    font-size: 35px;
                    text-align: left;
                    color: #000;
                    font-weight: 100;
                    margin: 20px 50px 20px 0px;
                    height: 85px;
                    align-items: center;
                    display: flex;
                }
                .card-text {
                    font-size: 14px;
                    height: 65px;
                }
                ul {
                    padding-left: 35px;
                    li {
                        margin: 4px 0;
                        &::before {
                            content: "";
                            border-radius: 50px;
                            margin-top: 8px;
                            width: 8px;
                            height: 8px;
                            background-color: #7c23f9;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
.sec3 {
    .btncenter {
        text-align: center;
        margin-top: 20px;
        .blackBtn {
            background-color: #000;
            border: 1px solid transparent;
            color: #fff;
            border-radius: 30px;
            padding: 0.375rem 1.5rem 0.575rem;
            &:hover {
                border: 1px solid #000;
                background-color: transparent;
                color: #000;
                -webkit-transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
                transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            &:active,
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.bgGrayTouch {
    background-color: #f2f2f2;
}

.phone-circle {
    animation: scrollDownAnimation 0.5s ease infinite alternate;
    -webkit-animation: scrollDownAnimation 0.5s ease infinite alternate;
}
@keyframes scrollDownAnimation {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}
@-webkit-keyframes scrollDownAnimation {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}
.phone-circle {
    position: fixed;
    bottom: 1em;
    left: 1em;
    cursor: pointer;
    z-index: 99;
    opacity: 1;
    transition: 0.5s;
    width: 3.75em;
    height: 3.75em;
    background-color: #6405d3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 27px 0 #d9d9ec;
    transform: translate(0, 0);
}

.navbar-light {

    .customArc {
        width: 150px;
        display: flex;
        justify-content: center;
        height: 120px;
        &::before {
            content: "";
            background: transparent;
            position: absolute;
            width: 175px;
            height: 120px;
            z-index: 1050;
        }
        img {
            z-index: 1051;
        }
    }
    .offcanvas {
        z-index: 1;
    }
    .wrapper-1 {
        background-color: #2a005b;
        padding: 0 25px 0 50px;
        .navbar-nav {
            .nav-link {
                color: #fff;
                font-size: 13px;
                margin-right: 1rem;
            }
        }
        .leadgentbtn {
            .list-inline-item:not(:last-child) {
                margin-right: 1rem;
            }
            .btn-primary {
                background-color: #3a1268;
                border-radius: 0;
                height: 55px;
                line-height: 2.5;
                border: 0;
            }
        }
    }
    .wrapper-2 {
        height: 65px;
        padding: 0 25px 0 50px;
        .leadgentbtn {
            align-items: center;
            display: flex;
            li {
                .btn-primary {
                    background-color: #ffcd1d;
                    width: 200px;
                    color: #000;
                    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
                    transform: translate3d(0, 0, 0);
                    perspective: 1000px;

                    @keyframes shake {
                        10%,
                        90% {
                            transform: translate3d(-1px, 0, 0);
                        }
                        20%,
                        80% {
                            transform: translate3d(2px, 0, 0);
                        }

                        30%,
                        50%,
                        70% {
                            transform: translate3d(-2px, 0, 0);
                        }
                        40%,
                        60% {
                            transform: translate3d(2px, 0, 0);
                        }
                    }
                }
            }
        }
        .navbar-nav {
            li {
                position: relative;
                margin: 0;
                .nav-link {
                    font-size: 10px;
                    &.active {
                        &::after {
                            content: "";
                            width: 100%;
                            height: 3px;
                            position: absolute;
                            bottom: -17px;
                            left: 0;
                            background-color: #370274;
                        }
                    }
                }
            }
        }
    }
}
.offcanvas.show ~ .modal-backdrop.show {
    opacity: 0;
}
button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}
.offcanvas-header {
    .btn-close {
        transform: rotate(180deg);
        &:hover {
            transform: rotate(360deg);
        }
    }
}
#back-to-top {
    left: 20px;
}
.thank-you-sec {
    background-color: #641cd3;
    .textpara-wrape {
        color: #ffffff;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: space-evenly;
        padding: 50px;
        h3 {
            font-weight: 900;
            font-size: 80px;
            line-height: 88px;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            margin-bottom: 50px;
            color: #ffffff;
        }
        p {
            font-size: 26px;
            line-height: 33px;
            letter-spacing: 0.01em;
        }
        .footet {
            h4 {
                color: #ffffff;
            }
            .footer-text {
                font-size: 50px;
                line-height: 52px;
                letter-spacing: -0.03em;
                span {
                    font-weight: bold;
                }
            }
        }
    }
    .image-wrape {
        img {
            display: block;
            object-fit: cover;
            width: 100%;
        }
    }
}
.grecaptcha-badge {
    visibility: hidden;
}
.offcanvas-header {
    padding: 0.3rem 1rem;
}
// .mobileMenu {
//     background-color: #3a1268;
//     &.navbar {
//         padding: 0px 0.5rem;
//     }
//     .logo-img {
//         background-color: #fff;
//         &::before{
//             background-color: rgb(255, 255, 255);
//             border-radius: 50%;
//             box-shadow: rgb(0 0 0 / 18%) 0px 1rem 3rem;
//             content: "";
//             height: 150px;
//             left: -50px;
//             position: absolute;
//             top: -50px;
//             width: 150px;
//             z-index: -1;
//         }
//     }
//     .phone-number {
//         background-color: transparent;
//         border: 0;
//     }
// }
.master-wrap {
    .btn-number {
        .animated-btn {
            height: 44px;
        }
        .phone-number {
            background-color: #6405d3;
            color: #fff;
            padding: 0.7rem 2rem;
            border: 0.1em solid var(--c);
            border-radius: 2em;
            width: 12em;
        }
    }
}

.tab-content-wraper {
    .tab-content {
        margin-top: 25px;
    }
    .card {
        .card-body {
            padding: 25px 100px;
            p {
                margin-bottom: 50px;
            }
        }
    }
}

.slick-list {
    &.draggable {
        cursor: grab;
    }
}
.hiddenStyle {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
//Isra
.home-revised-sec-1 {
    padding: 20px 0;
    background-color: #f2f2f2;
    h2 {
        font-family: circular_stdregular;
        font-style: normal;
        font-weight: 900;
        font-size: 120px;
        line-height: 99px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #641cd3;
        margin-bottom: 5px;
    }
    p {
        font-family: circular_stdregular;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 33px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #010101;
    }
    .lead {
        color: #000;
        font-size: 25px;
        font-weight: 700;
    }
}
.home-revised-sec-2 {
    background-color: #f2f2f2;
    .card {
        background: #ffffff;
        border-radius: 20px;
        .myform-label {
            font-family: "circular_stdregular";
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 45px;
            letter-spacing: -0.03em;
            color: #641cd3;
            pointer-events: none;
            border-width: 0;
            width: 100%;
            padding-right: 100px;
            resize: none;
            outline: none;
        }
        input {
            border-width: 0 0 1px 0;
            ::placeholder {
                font-family: "circular_stdregular";
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 33px;
                letter-spacing: 0.01em;
                color: #b1b1b1;
            }
            ::-moz-placeholder {
                font-family: "circular_stdregular";
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 33px;
                letter-spacing: 0.01em;
                color: #b1b1b1;
            }
            ::-webkit-input-placeholder {
                font-family: "circular_stdregular";
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 33px;
                letter-spacing: 0.01em;
                color: #b1b1b1;
            }
        }
        label.custom-control-label {
            font-family: "circular_stdregular";
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 45px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-left: 10px;
        }
        .heightControl {
            height: 125px;
        }
    }
    .myform-btn {
        span {
            padding-left: 10px;
        }
    }
}
.web-design-modal {
	background-color: rgba(0, 0, 0, 0.5) !important;
	.btn-outline-light {
		color: #6405d3;
		// border-color: #6405d3;
        border: 1px solid #6405d3;
        // color: #fff;
        border-radius: 30px;
        padding: 0.375rem 1.5rem 0.575rem;
	}
	#form6 {
		.form-control {
			margin-left: 1rem;
			width: 60%;
		}
	}
	.btn-success {
		color: #fff;
		background-color: #6405d3;
		// border-color: #6405d3;
        border: 1px solid #6405d3;
        color: #fff;
        border-radius: 30px;
        padding: 0.375rem 1.5rem 0.575rem;
	}
    .modal-content {
        margin: 90px 0px 0px 0px;
        background-color: #ececec;
    }
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
    .modal-header .close {
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
    }
}
#form1 {
	.row {
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	#form1 input[type="radio"] {
		width: 10%;
	}
	input#other {
		width: 60%;
	}
	label {
		width: 90%;
        // font-size: 18px;
        line-height: 27px;
        // color: #5e709d;
        font-family: "CircularStdMedium" !important;
	}
}
#form2 {
	.row {
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	#form1 input[type="radio"] {
		width: 10%;
	}
	input#other {
		width: 60%;
	}
	label {
		width: 90%;
        // font-size: 18px;
        line-height: 27px;
        // color: #5e709d;
        font-family: "CircularStdMedium" !important;
	}
}
#form3 {
	.row {
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	#form1 input[type="radio"] {
		width: 10%;
	}
	input#other {
		width: 60%;
	}
	label {
		width: 90%;
        // font-size: 18px;
        line-height: 27px;
        // color: #5e709d;
        font-family: "CircularStdMedium" !important;
	}
}
#form4 {
	.row {
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	#form1 input[type="radio"] {
		width: 10%;
	}
	input#other {
		width: 60%;
	}
	label {
		width: 90%;
        // font-size: 18px;
        line-height: 27px;
        // color: #5e709d;
        font-family: "CircularStdMedium" !important;
	}
}
#form5 {
	.row {
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5rem;
	}
	#form1 input[type="radio"] {
		width: 10%;
	}
	input#other {
		width: 60%;
	}
	label {
		width: 90%;
        // font-size: 18px;
        line-height: 27px;
        // color: #5e709d;
        font-family: "CircularStdMedium" !important;
	}
}
.career-box {
    margin: 30px 0 0;
    border: 1px solid #ccc;
    padding: 15px;
    position: relative;
    h2 {
    font-size: 20px;
    }    
    span{
        display: block;
    }
    .col-md-5 {
        display: flex;
        align-items: center;
    }
    .col-md-5:before {
        width: 4px;
        height: 100%;
        content: "";
        background: #6405d3;
        left: 0;
        position: absolute;
    }

    .animated-btn:hover  {
        span{
            transform: translateY(0)scale(4);
        }
        
    }
    
}
.dropdown-toggle {
    white-space: nowrap;
    color: #ffff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
}
#career-page{
    padding: 80px 0 100px;
}

/************************* Case Study Page CSS Start ****************************/
.casestudy-wrap {
  background: var(--blue-background);
}
.casestudy-sec1 {
  padding: 100px 0 50px;
  position: relative;
  min-height: 1100px;
  display: flex;
  align-items: center;
}
.switchFixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130%;
  filter: opacity(0.05);
  z-index: -1;
}
.innerSec-title {
  text-align: center;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerSec-title p {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 10px;
}
.innerSec-title:before {
  content: '';
  position: absolute;
  top: 30%;
  left: 0;
  width: 100px;
  height: 100%;
  background: url(../img/title-borderleft.png) no-repeat;
}
.innerSec-title:after {
  content: '';
  position: absolute;
  top: 30%;
  right: 0;
  width: 100px;
  height: 100%;
  background: url(../img/title-borderright.png) no-repeat;
  z-index: 6;
}
.caseStudy-contentWrap .innerSec-title {
  max-width: 480px;
}
.casestudySec2-text {
    max-width: 700px;
}
.innerHeading {
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
}
.caseStudy-content {
  max-width: 850px;
  margin: 0 auto;
}
.orangeHeading {
  color: var(--orange-color);
}
.caseStudy-sec1Image {
  background: url(../img/laptop-img.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: 600px;
  max-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0;
}
.caseStudy-sec1Image img, video {
    width: 100%;
    max-width: 990px;
    min-height: 510px;
    max-height: 510px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin: -5px 0 0;
}
.casestudy-sec2 {
  position: relative;
}
.casestudySec2-text .gray-paraghraph {
  text-transform: none;
}
.casestudySec2-image {
  position: relative;
}
.phone1 {
  max-width: 200px;
  object-fit: cover;
}
.phone2 {
  max-width: 280px;
  object-fit: cover;
  position: absolute;
  left: 5%;
  z-index: -1;
}
.phone3 {
  max-width: 320px;
  object-fit: cover;
  position: absolute;
  right: 25%;
  z-index: -2;
}
.casestudy-sec3 {
  padding: 70px 0;
}
.casestudySec3-image {
  position: relative;
}
.phone4 {
  position: absolute;
  top: 0;
  left: 0%;
}
.phone5 {
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 2;
}
.casestudy-sec4 {
  padding: 70px 0;
}
.casestudySec4-text {
  margin: 0 0 70px;
}
.casestudySec4-text .gray-paraghraph {
  text-transform: none;
}
.casestudySec4-image {
  position: relative;
}
.casestudySec4-image img {
  max-width: 400px;
  object-fit: cover;
}
.phone7-1 {
  position: absolute;
  top: 0;
  left: 1%;
  z-index: 1;
}
.phone7-2 {
  position: absolute;
  top: 0;
  left: 3%;
  z-index: 2;
}
.phone7-3 {
  position: absolute;
  top: 0;
  left: 4%;
  z-index: 3;
}
.casestudy-sec5 .casestudySec4-text {
  max-width: 890px;
  margin: 0 auto;
}
.screenSlider-wrap {
  position: relative;
  padding: 50px 0;
}
.screenBg {
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  overflow: hidden;
}
.casestudySec5-slider .owl-dots .owl-dot span {
  background: #fff !important;
}
.casestudySec5-slider .owl-dots .owl-dot.active span {
  background: #ec7b39 !important;
}
.owl-carousel .owl-item img {
    width: 100%;
    display: block;
    width: 290px !important;
    margin: 0 auto;
}
/*** Index Sec1 CSS Start ***/
/************************  Moments Page CSS Start  ****************/
.moments-sec1 {
    position: relative;
    background: #fff;
    min-height: 900px;
    display: flex;
    align-items: center;
    padding: 70px 0 0;
    overflow: hidden;
  }
  .moments-wrap section#sec-1:before {
      content: '';
      position: absolute;
      background: #2a252a;
      height: 100%;
      width: 56%;
      right: 0;
      z-index: -1;
  }
  .moments-wrap .lightOrange-color {
      color: #ff5757;
  }
  .moments-wrap .gomentor-image {
      position: relative;
      text-align: right;
  }
  .moments-wrap .innerHeading {
    text-transform: uppercase;
    color: #ff5757;
    font-size: 70px;
    font-weight: 900;
    position: relative;
    z-index: 5;
}
  .moments-wrap span.headingTitle {
      color: #000;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 600;
  }
  .moments-wrap .gomentor-content .gray-paragraph {
      color: #000;
      margin-top: 20px;
      max-width: 440px;
  }
  .moments-wrap .gomentor-content ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 60%;
      margin: 30px 0 0;
      padding: 0px;
  }
  ul.Industry2-ul li {
      margin-right: -4px;
  }
  .moments-wrap .gomentor-contentWrap.mb-4 {
      margin-top: 75px;
  }
  .moments-back-banner-contant {
      margin-bottom: 65px;
  }
  .moments-back {
      position: absolute;
      z-index: 1;
      left: 35%;
      top: 17%;
  }
  .moments-wrap .gomentor-sec2 {
      padding: 50px 0;
      background: #fff;
  }
  .moments-wrap .evoke-iconBox .text {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      color: #ff5757;
      transition: all 0.3s ease-in-out;
  }
  .moments-wrap .gomentor-sec3 {
      padding: 50px 0;
      position: relative;
      background: #fff;
  }
  .moments-wrap .small-title {
      font-size: 26px;
      font-weight: 600;
      color: #ff5757;
      text-transform: uppercase;
  }
  .gomentorVector22 {
      position: absolute;
      bottom: 41%;
      left: 0;
      z-index: -1;
  }
  .moments-wrap .taskiez-sec6 .left-arrow, .right-arrow {
      background: #ff5757;
      color: #ffffff;
  }
  .moments-wrap .taskiez-sec6 {
      background: #fff;
      position: relative;
  }
  .moments-wrap .innerHeading-mom {
      font-size: 42px;
      font-weight: 900;
      text-transform: uppercase;
      color: #ff5757;
  }
  .glyphicon-play:before {
      content: "\e072";
  }
  .moments-wrap .section-count span {
      display: none;
  }
  /************************  Moments Page CSS End ****************/
  /************************ Taskiez Page CSS Start ****************/
.taskiez-wrap {
    background: #1d181d;
  }
  .taskiez-sec1 {
    background: url(../img/moments/taskiezSec1-bg.jpg)no-repeat;
    background-size: cover;
    padding: 100px 0 50px;
    position: relative;
    min-height: 1000px;
    display: flex;
    align-items: center;
  }
  .gradient-color {
    background: linear-gradient(160deg, rgba(75,224,239,1) 16%, rgba(209,186,210,1) 65%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .taskiez-sec1 .gray-paraghraph {
    line-height: 1.8;
  }
  .taskiez-sec2 {
    position: relative;
    background: #151515;
    padding: 70px 0;
  }
  .taskiezSec2-vector1 {
    position: absolute;
    top: 5%;
    left: 0;
    max-width: 400px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    filter: grayscale(0.8);
    z-index: -1;
  }
  .taskiezSec2-vector2 {
    position: absolute;
    top: 10%;
    right: 0;
    max-width: 80px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }
  .taskiezSec2-head {
      max-width: 900px;
      margin: 0 auto 50px;
  }
  .taskiezSec2-head .gray-paraghraph {
    text-transform: none;
  }
  .zionSec2-wrap {
    margin-top: 100px;
  }
  .taskiezSec2-item {
    background: #626161;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 15px 15px 15px 0;
    overflow: hidden;
  }
  .taskiezSec2-item .text {
    text-align: center;
    width: calc(100% - 85px);
    font-size: 16px;
    font-weight: 700;
    color: var(--white-color);
    text-transform: uppercase;
  }
  .taskiezSec2-item .icon {
    background: #4ae1ef;
    border-radius: 15px 15px 0 0;
    max-width: 85px;
    width: 100%;
    max-height: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .taskiezSec2-item .icon img {
    max-width: 45px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0) invert(0);
  }
  .taskiez-sec3 {
    position: relative;
    background: url(../img/moments/taskiezSec3-bg.jpg) no-repeat;
    background-size: cover;
    min-height: 800px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .taskiezSec3-rightImg {
    position: absolute;
    top: -15%;
    right: 10%;
    z-index: -1;
    max-width: 490px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .taskiezSec3-vector1 {
    position: absolute;
    bottom: 0;
    right: 35%;
    z-index: -2;
    max-width: 280px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .taskiez-sec4 {
    position: relative;
    background: url(../img/moments/taskiezSec4-bg.jpg) no-repeat;
    background-size: cover;
    padding: 100px 0;
    overflow: hidden;
  }
  .taskiezSec4-vector1 {
    position: absolute;
    bottom: 10%;
    right: 0;
    object-fit: cover;
    object-position: center;
    max-width: 200px;
    width: 100%;
    z-index: -1;
  }
  .taskiez-sec5 {
    position: relative;
    padding: 80px 0;
    background: url(../img/moments/taskiezSec5-bg.jpg) no-repeat;
    background-size: cover;
  }
  .taskiezSec5-image img {
    max-width: 880px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .taskiezSec5-vector1 {
    position: absolute;
    bottom: 0;
    right: 12%;
    object-fit: cover;
    object-position: center;
    max-width: 330px;
    width: 100%;
    z-index: -1;
  }
  .taskiez-sec6 {
    position: relative;
    padding: 70px 0;
    background: #151515;
    overflow: hidden;
  }
  .taskiez-sec6 .swiper-slide-active .img-box img {
    min-height: 570px;
    border-radius: 30px;
  }
  .taskiez-sec6 .img-box img {
    max-height: 550px;
    min-height: 550px;
  }
  .taskiez-sec6 .abs-mobile-body {
    top: 61%;
  }
  .taskiez-sec6 .abs-mobile-body img {
    max-width: 300px;
    height: 600px;
  }
  .taskiez-sec6 .slider-nav {
    bottom: 2%;
  }
  .taskiez-sec6 .left-arrow, .right-arrow {
    background: #dfb5e1;
    color: #151515;
  }
  .taskiez-wrap .section-count {
      display:none;
  }
  .taskiez-wrap .innerHeading {
      font-size: 78px;
  }
  .taskiez-wrap .section-heading h5 {
      font-size: 78px;
  }
  /************************ Taskiez Page CSS End ****************/
  
/************************* eVoke Page CSS Start ****************************/
.evoke-wrap {
    background: #fff;
    position: relative;
    z-index: 10;
  }
  .dropdown-menu {
    background: #2a005b;
}

.dropdown-menu a {
    color: #fff;
}
  .evoke-sec1 {
    min-height: 850px;
    z-index: 99;
    align-items: center;
    padding: 0;
    display: flex;
    position: relative;
}
  .switchFixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130%;
    filter: opacity(0.05);
    z-index: -1;
  }
  .yellowColor {
    color: #ffcd1d;
}
.evokeSec2-wrap p {
    color: #fff;
}
  .evoke-contentWrap .innerSec-title {
    max-width: 480px;
  }
  .evokeSlider-wrap {
    position: relative;
  }
  .evokeSlider-wrap {
    padding: 40px 0px;
  }
  .whitecaseBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    max-width: 235px;
    width: 100%;
    overflow: hidden;
  }
  .evokeSlide {
    transition: all 0.3s ease-in-out;
  }
  .evokeSlide {
    transform: matrix3d(1, -0.5, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1.4);
  }
  .evokeSlider .center .evokeSlide {
    transform: none !important;
  }
  .evokeSlide img {
    min-height: 365px;
    max-height: 365px;
    object-fit: cover;
    object-position: center;
  }
  .evoke-sec2 {
    padding: 0 0 70px;
  }
  .evoke-sec2 .row {
    align-items: center;
  }
  .evokeSec2-head {
    margin-bottom: 50px;
  }
  .evokeSec2-head .gray-paraghraph {
    text-transform: none;
  }
  .evoke-iconBox {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background: #131212;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 0 10px 0 #000000d9;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .evoke-iconBox:hover .text{
    color: #fff !important;
  }
  .evoke-iconBox:hover .icon img {
    filter: grayscale(1);
  }
  .evoke-iconBox .icon img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
  }
  .evoke-iconBox .text {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fcad04;
    transition: all 0.3s ease-in-out;
  }
  .evokeSec2-image {
    position: relative;
    text-align: right;
  }
  .evokeSec2-image .macbook {
    min-height: 450px;
    max-height: 450px;
    object-fit: cover;
    object-position: center;
  }
  .evokeSec2-image .iPhone {
    position: absolute;
    top: 10%;
    left: 0;
    max-width: 120px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .evoke-sec3 {
    padding: 50px 0;
  }
  .innerSec-content .gray-paraghraph {
    text-transform: none;
  }
  .evokeSec3-images {
    position: relative;
  }
  .evokeSec3-images img {
    object-fit: cover;
    object-position: center;
    min-height: 600px;
    max-height: 600px;
  }
  .career-box ul {
    height: 170px;
    overflow-y: auto;
}
/* browser scroller style */
::-webkit-scrollbar { width:10px; height:10px; }
::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment { display:none; }
::-webkit-scrollbar-track-piece { background-color:#ecedf1; -webkit-border-radius:0px; border-left:1px solid #888; }
::-webkit-scrollbar-thumb:vertical { -webkit-border-radius:0px; background:#6405d3; }


/* browser scroller style */
.career-box ul::-webkit-scrollbar { width:10px; height:10px; }
.career-box ul::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment { display:none; }
.career-box ul::-webkit-scrollbar-track-piece { background-color:#ecedf1; -webkit-border-radius:0px; border-left:1px solid #fff; }
.career-box ul::-webkit-scrollbar-thumb:vertical { -webkit-border-radius:0px; background:#6405d3; }

  .evokeScreen2 {
    position: absolute;
    top: 0;
    left: 10%;
  }
  .evokeScreen3 {
    position: absolute;
    top: 0;
    left: 20%;
  }
  .evoke-sec4 {
    padding: 50px 0;
  }
  .evokeSec4-images {
    position: relative;
    min-height: 500px;
  }
  .evokeSec4-images img {
    min-height: 580px;
    max-height: 580px;
    object-fit: cover;
    object-position: center;
  }
  .evokeScreen4 {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 2;
  }
  .evokeScreen5 {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 1;
  }
  .evokeScreen6 {
    position: absolute;
    top: 0;
    right: 0%;
  }
  .evoke-sec5 {
    padding: 70px 0;
  }
  .evokeSec5-head {
    max-width: 950px;
    margin: 0 auto 30px;
  }
  .evokeSec5-head .gray-paraghraph {
    text-transform: none;
  }
  .evokeSec5-image img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .evoke-sec6 {
    padding: 70px 0;
  }
  .evokeSec6-wrap {
    position: relative;
    padding: 70px 0;
  }
  .evokeSec6-wrap .screenBg {
    max-width: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .evokeSec6-slide {
    padding: 15px 0;
  }
  .evokeSec6-slide img {
    min-height: 500px;
    max-height: 500px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 10px 0 #eaaf00;
  }
  .evokeSec6-slider .owl-item.center .evokeSec6-slide img {
    box-shadow: none;
  }
  .evokeSec6-slider .owl-dots {
    margin-top: 50px !important;
  }
  .evokeSec6-slider .owl-dots .owl-dot span {
    background: #fff !important;
  }
  .evokeSec6-slider .owl-dots .owl-dot.active span {
    background: var(--yellow-color) !important;
  }
  /************************* eVoke Page CSS End ****************************/
  
  /************************* Kornhole Page CSS Start ****************************/
  .green-color {
    color: var(--green-color);
  }
  .kornhole-wrap {
    position: relative;
    background: #fff;
  }
  .kornhole-sec1 {
    padding: 100px 0 50px;
    position: relative;
    min-height: 1100px;
    display: flex;
    align-items: center;
  }
  .kornhole-contentWrap .innerSec-title {
    max-width: 480px;
  }
  .kornhole-image {
    position: relative;
    text-align: center;
    padding: 40px 0 0;
  }
  .kornholeLogo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 450px;
    object-fit: cover;
    object-position: center;
  }
  .kornholeVector1 {
    position: absolute;
    top: 0;
    left: 10%;
    max-width: 180px;
    object-fit: cover;
    object-position: center;
  }
  .kornholeVector2 {
    position: absolute;
    top: 0;
    left: 25%;
    max-width: 180px;
    object-fit: cover;
    object-position: center;
    z-index: 2;
  }
  .kornhole-sec2 {
    padding: 70px 0;
  }
  .kornhole-sec2 .row {
    align-items: center;
  }
  .kornholeSec2-head {
    margin-bottom: 50px;
    max-width: 730px;
  }
  .kornholeSec2-head .gray-paraghraph {
    text-transform: none;
  }
  .kornhole-iconBox {
    width: 100%;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    background: #2a252a;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 0 10px 0 #000000d9;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .kornhole-iconBox p {
    color: #ccdf00;
}
  .kornhole-iconBox:hover .text{
    color: #fff !important;
  }
  .kornhole-iconBox:hover .icon img {
    filter: grayscale(1);
  }
  .kornhole-iconBox .icon img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
  }
  .kornhole-iconBox .text {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--green-color);
    transition: all 0.3s ease-in-out;
  }
  .kornholeSec2-image {
    position: relative;
  }
  .kornholeSec2-image img {
    object-fit: cover;
    object-position: center;
  }
  .kornholeSec2-img1 {
    margin: -150px 0 0;
  }
  .kornholeSec2-img2 {
    position: absolute;
    bottom: 0;
    left: -8%;
    right: 0;
    margin: 0 auto;
    max-width: 250px;
  }
  .kornhole-sec3 {
    padding: 70px 0;
  }
  .kornholeSec3-head {
    max-width: 770px;
    margin: 0 auto 30px;
  }
  .kornholeSec3-head .gray-paraghraph {
    text-transform: none;
  }
  .kornhole-sec4 {
    padding: 70px 0;
  }
  .kornholeSec4-images {
    position: relative;
    min-height: 530px;
  }
  .kornholeSec4-images img {
    min-height: 530px;
    max-height: 530px;
    object-fit: cover;
    object-position: center;
  }
  .kornholeScreen4 {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 3;
  }
  .kornholeScreen3 {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 2;
  }
  .kornholeScreen2 {
    position: absolute;
    top: 0;
    right: 0%;
    z-index: 1;
  }
  .kornholeScreen1 {
    position: absolute;
    top: 0;
    right: 0%;
  }
  .kornhole-sec5 {
    padding: 70px 0;
  }
  .kornholeSec5-head {
    max-width: 1100px;
    margin: 0 auto 80px;
  }
  .kornholeSec5-head .gray-paraghraph, .kornholeSec5-head .gray-paraghraph {
    text-transform: none;
  }
  .kornholeSec5-image {
    position: relative;
    text-align: right;
  }
  .kornholeSec5-image img {
    object-fit: cover;
    object-position: center;
  }
  .kornholeSec5-image .kornholeKing {
    max-width: 400px;
    width: 100%;
    position: absolute;
    bottom: -15%;
    left: 1%;
    z-index: 1;
  }
  .kornhole-sec6 {
    padding: 70px 0;
  }
  .kornholeSec6-wrap {
    position: relative;
    padding: 65px 0;
  }
  .kornholeSec6-slide {
    padding: 15px 0;
  }
  .kornholeSec6-slide img {
    min-height: 470px;
    max-height: 470px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 10px 0 var(--green-color);
  }
  .kornholeSec6-slider .owl-item.center .kornholeSec6-slide img {
    box-shadow: none;
  }
  .kornholeSec6-wrap .screenBg {
    top: 3%;
  }
  .kornholeSec6-slider .owl-dots {
    margin-top: 100px !important;
  }
  .kornholeSec6 .owl-dots .owl-dot span {
    background: #fff !important;
  }
  .kornholeSec6-slider .owl-dots .owl-dot.active span {
    background: var(--green-color) !important;
  }
  /************************* Kornhole Page CSS End ****************************/
  
  /************************* Checkmate Page CSS Start ****************************/
  .lightgreen-color {
    color: #fff;
  }
  .checkmate-wrap {
    position: relative;
    background: #fff;
  }
  .checkmate-sec1 {
    padding: 100px 0 50px;
    position: relative;
    min-height: 1100px;
    display: flex;
    align-items: center;
  }
  .check-leftVector {
    position: absolute;
    top: 35%;
    left: 0;
  }
  .check-rightVector {
    position: absolute;
    top: 20%;
    right: 0;
  }
  .checkmate-images {
    text-align: right;
    margin: 70px 0 0;
    position: relative;
  }
  .checkmate-images img {
    object-fit: cover;
    object-position: center;
  }
  .checkVector2 {
    position: absolute;
    bottom: 0;
    left: 19%;
    z-index: 1;
  }
  .checkVector1 {
    position: absolute;
    bottom: 0;
    left: 0%;
    z-index: 2;
  }
  .border-line {
    display: flex;
    align-items: center;
    max-width: 630px;
    width: 100%; 
    position: absolute;
    transition: all 5s ease-in-out;
  }
  .left-line {
   bottom: 5%;
   left: 0;
   flex-direction: row-reverse;
  }
  .right-line {
    max-width: 550px;
    top: 45%;
    right: 0;
  }
  .border-line .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #24b9b5;
    transition: all 2s ease-in-out;
    animation: pulse-animation 2s infinite;
  }
  .border-line .line {
    display: inline-block;
    width: 99%;
    height: 1px;
    background: #24b9b5;
  }
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px #3ac3bf;
    }
    100% {
      box-shadow: 0 0 0 10px #3ac3bf;
    }
  }
  .white-color {
    color: #fff;
  }

  .checkmateSec2-wrap {
    padding: 70px 0;
  }
  .checkmateSec2-wrap .row {
    align-items: center;
  }
  .optionBox {
    color: #000;
    max-width: 300px;
  }
  .optionBox .number {
    font-size: 40px;
    font-family: var(--monoton-font-family);
    padding: 0 40px;
    color: #000;
  }
  .optionBox .titleBox {
    width: 100%;
    display: flex;
    align-items: center;
    background: #12918e;
    border-radius: 30px;
    position: relative;
    padding: 15px 50px;
  }
  .optionBox .titleBox .title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    color:#fff;
  }
  .optionBox .titleBox .icon {
    background: #12918e;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .optionBox .titleBox .icon img {
    filter: brightness(0) invert(1);
    max-width: 40px;
    object-fit: cover;
    object-position: center;
  }
  .mb_7 {
    margin-bottom: 70px;
  }
  .checkmate-sec3 {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
  }
  .checkmate-sec3 .row {
    align-items: center;
  }
  .checkmateSec3-content {
    position: relative;
    background: #fff;
    border-radius: 70px;
    padding: 25px 20px;
  }
  .checkmateSec3-content:before {
    content: '';
    position: absolute;
    bottom: -10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    background: #fff;
    transform: rotate(45deg);
    z-index: -1;
  }
  .checkmateSec3-content p {
    font-size: 17px;
    font-weight: 500;
    color: #1d181d;
  }
  .checkmate-leftImage {
    margin: 50px 0 0;
  }
  .checkmate-rightImage {
    text-align: right;
    position: relative;
    z-index: 5;
}
  .wave {
    position: absolute;
    bottom: -30%;
    right: 0;
    z-index: 1;
    max-width: 1250px;
    filter: brightness(0.6);
  }
  .checkmate-sec4 {
    padding: 70px 0;
  }
  .checkmate-sec4 .row {
    align-items: center;
  }
  .checkmate-contentWrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .checkmateSec4-content .gray-paraghraph {
    text-transform: none;
  }
  .imageBorder {
    width: 100%;
  }
  .imageBorder img {
    object-fit: cover;
    object-position: center;
  }
  .checkmate-sec5 {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
  }
  .checkmateSec5-head {
    max-width: 1050px;
    margin: 0 auto;
  }
  .checkmateSec5-head .gray-paraghraph {
    text-transform: none;
  }
  .checkmateSec5-wrap {
    margin: 70px 0 0;
  }
  .checkmateSec5-wrap img {
    object-fit: cover;
    object-position: center;
  }
  .checkmateSec5-wrap .leftCol-image img {
    max-width: 580px;
    width: 100%;
    position: relative;
    z-index: 5;
}
  .rightCol-image {
    text-align: center;
  }
  .wave2 {
    position: absolute;
    bottom: 0;
    left: -4%;
    z-index: 1;
    max-width: 1450px;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.6);
  }
  .checkmate-sec6 {
    padding: 70px 0;
    background: #fff;
    position: relative;
    overflow: hidden;
    min-height: 1200px;
  }
  .screen-slider-wrap {
    padding: 45px 0;
  }
  .sec1-animatedImage1, .sec1-animatedImage2, .sec1-animatedImage3 {
    display: none;
}

  .abs-mobile-body {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50% , -50%);
    overflow: hidden;
  }
  .abs-mobile-body img {
    max-width: 330px;
    width: 100%;
    height: 600px;
    border-radius: 50px;
    position: relative;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    object-fit: contain;
    object-position: center;
  }
  .img-box img {
    max-height: 480px;
    min-height: 480px;
    object-fit: contain;
    object-position: center;
    width: 280px !important;
    margin: 0 0 0 15px;
}
  .swiper-slide-active .img-box img {
    min-height: 480px;
    object-fit: cover;
    object-position: center;
  }
  .slider-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 150px;
    margin: 0 auto;
    position: absolute;
    bottom: 8%;
    left: 0;
    right: 0;
  }
  .left-arrow, .right-arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #12918e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  }
  /********************** Checkmate Page CSS End *********************/
  
  /******************** Gomentor Page CSS Start *********************/
  .gomentor-wrap {
    background: #fff;
  }
  .gomentor-sec1 {
    padding: 100px 0 50px;
    position: relative;
    min-height: 700px;
    display: flex;
    align-items: center;
  }
  .gomentor-contentWrap .innerSec-title {
    max-width: 480px;
    margin: 0 0;
  }
  .lightOrange-color {
    color: var(--lightOrange-color);
  }
  .gomentor-content .gray-paragraph {
    color: #000;
  }
  .gomentor-content ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
  }
  .gomentor-content ul li {
    display: inline-block;
  }
  .gomentor-content ul li span {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
  }
  .gomentor-content ul li p {
    font-size: 16px;
    font-weight: 400;
    color: #9c9c9c;
  }
  .gomentor-image {
    position: relative;
    text-align: right;
  }

  .gomentor-image video {
    max-width: 228px;
    min-height: 480px;
    max-height: 500px;
    object-fit: cover;
    object-position: center;
    border-radius: 30px;
    margin: 13px 0 0;
}
  .gomentor-sec2 {
    padding: 50px 0;
  }
  .gomentorSec2-row2 {
    margin: 70px 0;
  }
  .gomentor-sec2 .evoke-iconBox {
    background: #2a252a;
  }
  .gomentor-sec3 {
    padding: 50px 0;
    position: relative;
  }
  .gomentorSec3-content {
    padding-right: 30px;
  }
  .small-title {
    font-size: 26px;
    font-weight: 600;
    color: var(--lightOrange-color);
    text-transform: uppercase;
  }
  .small-title span {
    font-size: 70px;
    font-weight: 300;
  }
  .gomentor-sec3 .gray-paragraph {
    font-size: 15px;
    line-height: 1.8;
    color: #9c9c9c;
  }
  .gomentorVector1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .gomentorVector2 {
    position: absolute;
    bottom: 15%;
    left: 0;
    z-index: 1;
  }
  .gomentor-sec4 {
    padding: 70px 0;
    position: relative;
    overflow: hidden;
    background: #fff;
  }
  .gomentor-sec4 .checkmateSec5-head {
    max-width: 800px;
  }
  .gomentor-sec4 .abs-mobile-body {
    top: 60%;
  }
  /******************** Gomentor Page CSS Start *********************/
  
  /************************ Octavia Page CSS Start ****************/
  .octavia-wrap {
    background: #fff;
  }
  .octavia-sec1 {
    background: #fff;
    padding: 100px 0 50px;
    position: relative;
    min-height: 1000px;
    display: flex;
    align-items: center;
  }
  .octaviaSec1-leftCol {
    position: relative;
  }
  .octavia-phone2 {
    position: absolute;
    top: 0;
    left: 12%;
  }
  .octaviaSec1-leftCol img {
    max-height: 700px;
    min-height: 700px;
    object-fit: cover;
    object-position: center;
  }
  .bg-image {
    position: absolute;
    bottom: 3%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
  .octaviaSec1-rightCol {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .octaviaSec1-rightCol .image img {
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    min-height: 150px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #c31a21;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #fff;
    transition: all 2s ease-in-out;
    animation: pulse 2s infinite;
    position: absolute;
  }
  @keyframes pulse {
    from {
      box-shadow: 0 0 0 0 #c31a21;
    }
    to {
      box-shadow: 0 0 20px 0 #c31a21;
    }
  }
  .circle1 {
    right: 25%;
    bottom: 15%;
  }
  .circle2 {
    top: 15%;
    right: 20%;
  }
  .circle3 {
    top: 50%;
    right: 10%;
  }
  .octavia-sec1 .fixed-anchors {
    right: -3%;
  }
  .octavia-sec2 {
    background: #fff;
    padding: 70px 0;
    position: relative;
  }
  .octaviaSec2-wrap {
      margin: 150px 0 0;
  }
  .octaviaSec2-wrap .evoke-iconBox {
    background: #fff;
  }
  .octaviaSec2-wrap .evoke-iconBox .icon img {
    filter: invert(22%) sepia(33%) saturate(7037%) hue-rotate(344deg) brightness(76%) contrast(101%);
  }
  .octaviaSec2-wrap .evoke-iconBox .text {
    color: #c31a21 !important;
  }
  .octaviaSec2-image {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: relative;
  }
  .octavia-phone4 {
    max-width: 331px;
    max-height: 410px;
    min-height: 410px;
    object-fit: cover;
    object-position: center;
  }
  .octavia-phone5 {
    position: absolute;
    top: -35%;
    right: 0;
    z-index: -1;
    max-width: 363px;
    max-height: 555px;
    min-height: 555px;
    object-fit: cover;
    object-position: center;
  }
  .octavia-sec2 .circle2 {
    right: unset;
    left: 5%;
  }
  .octavia-sec3 {
    background: #fff;
    padding: 70px 0 0;
    position: relative;
  }
  .octavia-sec3 .row {
    align-items: center;
  }
  .octaviaSec3-leftCol img {
    max-height: 595px;
    min-height: 595px;
    object-fit: cover;
    object-position: center;
  }
  .text-transform {
    text-transform: none !important;
  }
  .octavia-sec4 {
    background: #fff;
    padding: 70px 0;
    position: relative;
  }
  .octaviaSec4-rightCol {
    text-align: right;
  }
  .octaviaSec4-rightCol img {
    object-fit: cover;
    object-position: center;
    max-height: 535px;
    min-height: 535px;
  }
  .octavia-sec5 {
    background: #fff;
    padding: 70px 0;
    position: relative;
  }
  .innerSec-head {
    max-width: 900px;
    margin: 0 auto;
  }
  .octaviaSec5-image {
    position: relative;
    text-align: center;
    margin: 130px 0 0;
  }
  .octaviaSec5-image img {
      object-fit: cover;
      object-position: center;
      max-width: 100%;
      max-height: 380px;
      min-height: 380px;
      width: 100%;
  }

  .octavia-sec6 {
    padding: 70px 0;
    background: #fff;
    position: relative;
    overflow: hidden;
  }
  .octavia-sec6 .innerSec-head {
    margin: 0 auto 30px;
  }
  .octavia-sec6 .abs-mobile-body {
    position: absolute;
    top: 60%;
  }
  .octavia-sec6 .slider-nav {
    display: none;
  }
  /************************ Octavia Page CSS End ****************/
  
  /************************ EMS Page CSS Start ****************/
  .EMS-wrap {
    background: #151515;
  }
  .EMS-sec1 {
    background: #fff;
    padding: 100px 0 50px;
    position: relative;
    min-height: 1000px;
    display: flex;
    align-items: center;
  }
  .EMSsec1-leftCol {
    position: relative;
  }
  .EMS-phone2 {
    position: absolute;
    top: 0;
    left: 12%;
  }
  .EMSsec1-leftCol img {
    max-height: 700px;
    min-height: 700px;
    object-fit: cover;
    object-position: center;
  }
  .EMSsec1-rightCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .red-color {
    color: #e10e07;
  }
  .stoke-heading span {
    font-size: 130px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.0;
  }
  .stroke-text {
    -webkit-text-stroke: 2px #e10e07;
    -moz-text-stroke: 2px black;
    color: transparent;
    display: inline-block;
  }
  .bottom-text {
    /* justify-content: space-between; */
    align-items: center;
    padding: 0 0px 0 100px;
    display: flex;
    letter-spacing: 72px;
}
  .bottom-text span {
    font-size: 130px;
    font-weight: 800;
    color: #000;
  }
  .EMS-sec2 {
    background: #fff;
    padding: 70px 0;
    position: relative;
  }
  .EMSsec2-head {
    max-width: 650px;
    margin: 0 0 50px;
  }
  .fixed-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
  }
  .EMSsec2-wrap .octaviaSec2-image {
    justify-content: space-around;
  }
  .EMSsec2-wrap .evoke-iconBox .icon img {
    filter: grayscale(1);
  }
  .EMSsec2-wrap .evoke-iconBox .text {
    color: #e10e07 !important;
}
  .EMSsec2-wrap .evoke-iconBox {
    background: #fff;
  }
  .EMS-sec3 {
    padding: 70px 0;
    background: #fff;
  }
  .EMS-sec4 {
    position: relative;
    padding: 70px 0;
    background: #fff;
  }
  .EMS-sec4 .innerSec-head {
    margin: 0 auto;
    text-align: center;
  }
  .fixed-image2 {
    position: absolute;
    top: -20%;
    left: 0;
    z-index: -1;
  }
  .EMS-sec5 {
    position: relative;
    padding: 100px 0;
    background: #fff;
  }
  .fixed-image3 {
    position: absolute;
    top: 0%;
    left: 0;
    z-index: -1;
  }
  .EMS-sec5 .innerSec-head {
    margin: 0 auto 50px;
  }
  .EMS-sec5-image {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .EMS-sec6 {
    padding: 70px 0;
    background: #fff;
    position: relative;
  }
  .EMS-sec6 .innerSec-head {
    margin: 0 auto;
  }
  .EMS-sec6 .abs-mobile-body {
    top: 60%;
  }
  .EMS-sec6 .fixed-image2 {
    top: -10%;
  }
  /************************ EMS Page CSS End ****************/

  /************************ Zion Page CSS Start ****************/
  .zion-wrap {
    background: #fff;
  }
  .zion-sec1 {
    background: #fff;
    padding: 00px 0 50px;
    position: relative;
    min-height: 1000px;
    display: flex;
    align-items: center;
  }
  .lightYellow-color {
    color: #6405d3;
}
section.zion-sec2 .evoke-iconBox.text-center p {
    color: #fff;
}
  .darkGray-color {
    color: #000;
  }
  .zionSec1-leftCol .innerHeading {
    line-height: 1.2;
  }
  .zionSec1-rightCol img {
    width: 100%;
    max-width: 660px;
    object-fit: cover;
    object-position: center;
  }
  .zion-sec2 {
    background: #fff;
    padding: 70px 0 0;
    position: relative;
  }
  .zion-sec3 {
    background: #fff;
    padding: 100px 0;
    position: relative;
  }
  .zion-sec4 {
    background: #fff;
    padding: 100px 0;
    position: relative;
  }
  .zionSec4-leftCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .zionSec4-leftCol img {
    max-width: 180px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .zionSec4-rightCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mt-100 {
    margin-top: -100px;
  }
  .mt-50 {
    margin-top: -50px;
  }
  .zion-sec5 {
    background: #fff;
    padding: 100px 0;
    position: relative;
  }
  /************************ Zion Page CSS End ****************/
  .sec1-animatedImage1 {
    position: absolute;
    top: 10%;
    left: 0;
  }
  .sec1-animatedImage2 {
    position: absolute;
    top: 10%;
    left: 0;
  }
  .sec1-animatedImage3 {
    position: absolute;
    top: 10%;
    right: 0;
  }
  .index-sec1 .row {
    align-items: center;
  }
  .inner-secWrap {
    position: relative;
  }
  .index-sec1 .banner-image {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .banner-content {
    margin-left: 35px;
  }
  .gray-heading {
    font-size: 60px;
    font-weight: 900;
    color: #9c9c9c;
    text-transform: uppercase;
    line-height: 1.2;
  }
  .gray-heading span {
    color: #c22026;
  }
  .gray-paraghraph {
      font-size: 16px;
      font-weight: 500;
      color: #b5b5b5;
      text-transform: unset;
      padding: 10px 0;
  }
  .banner-button {
    margin: 35px 0 0;
  }
  .cta-btn {
      font-size: 12px;
      font-weight: 700;
      color: #9c9c9c;
      text-transform: uppercase;
      border: 2px solid #c22026;
      border-radius: 30px;
      padding: 12px 20px;
      display: inline-flex;
      align-items: center;
      transition: all 0.3s ease-in-out;
      letter-spacing: 2px;
      background: transparent;
  }
  .cta-btn:hover {
    background: #c22026 !important;
    border-color: #c22026 !important;
    color: #fff !important;
  }
  .cta-btn:hover i {
    color: #fff;
    margin-left: 15px;
  }
  .cta-btn i {
    color: #c22026;
    font-size: 22px;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
  }
  .banner-image {
    text-align: right;
  }
  .banner-image img {
    max-width: 450px;
    object-fit: cover;
  }
  .video-frame {
    max-width: 250px !important;
    position: absolute;
    top: 0;
    right: -2%;
  }
  .banner-image video {
    max-width: 225px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 30px;
    max-height: 510px;
    min-height: 500px;
  }
  .banner-img1 {
    max-width: 160px !important;
    position: absolute;
    top: 12%;
    left: 32%;
    z-index: -1;
  }
  .fixed-anchors {
   position: absolute;
   bottom: 46%;
   right: 0%;
   transform: rotate(-90deg);
   width: 100%;
   max-width: 301px;
  }
/************************************** END *************************************/
.ct-fld {
    margin: 25px 0 0 0;
}
section#career-form {
    padding: 55px 0;
}

.modal-header {
	border: 0 !important;
}
.modal-footer {
	border: 0 !important;
}
#form6 {
	.row {
		margin-bottom: 0.5rem;
	}
}
.web-design-modal-label {
    display: block;
    position: relative;
    padding-left: 35px;
    // margin-bottom: 12px;
    cursor: pointer;
    // font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .web-design-modal-label input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .labelcheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    // background-color: #eee;
    background-color: #ccc;
    border-radius: 50%;
    bottom: 0;
    margin: auto;
  }
  
  /* On mouse-over, add a grey background color */
  .web-design-modal-label:hover input ~ .labelcheckmark {
    background-color: #6405d3;

  }
  
  /* When the radio button is checked, add a blue background */
  .web-design-modal-label input:checked ~ .labelcheckmark {
    background-color: #6405d3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .labelcheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .web-design-modal-label input:checked ~ .labelcheckmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .web-design-modal-label .labelcheckmark:after {
       top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
