@media (min-width: 1680px) {
    .home-revised-sec-2 .card .myform-label {
        font-size: 32px;
        line-height: 43px;
    }
}
@media (min-width: 1400px) {
    .phone-circle {
        display: none;
    }
    .home-revised-sec-1 h2,
    .home-revised-sec-1 .h2 {
        font-size: 43px;
        line-height: 48px;
    }
    .about-us-banner,
    .packages-banner,
    .contact-us-banner,
    .ecommerce-website-design-banner,
    .portfolio-banner,
    .web-design-banner,
    .mobile-app-banner,
    .logo-app-banner,
    .seo-design-banner,
    .digital-design-banner,
    .video-design-banner,
    .packaging-design-banner,
    .privacy-banner,
    .term-condition-banner{
    margin-top: 0;
    }
}
// Large devices (desktops, less than 992px)
@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
    }
    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }
    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
    .w-sm-50 {
        width: 50%;
    }
}
@media (min-width: 1399.98px) {
    .offcanvas {
        background: none;
        border: none;
        justify-content: end;
    }
    .w-xxl-75 {
        width: 75%;
    }
    .w-xxl-25 {
        width: 25%;
    }
    .position-xxl-relative {
        position: relative;
    }
    .position-xxl-absolute {
        position: absolute;
    }
    .portfolio-filter-sec .filters-button-select .button-group .btn {
        width: 140px;
    }
}
// Large devices (desktops, less than 1199.98px)
@media (min-width: 1199.98px) {
    .w-lg-50 {
        width: 50%;
    }
    .w-xl-50 {
        width: 50%;
    }
    .w-xl-75 {
        width: 75%;
    }
    .w-lg-25,
    .w-xl-25 {
        width: 25%;
    }
}

// Large devices (desktops, less than 1400px)
@media screen and (max-width: 1400px) {
    .slider-banner-1,
    .slider-banner-2,
    .slider-banner-3 {
        .bannerSlider {
            img {
                width: 800px;
            }
        }
    }
    .leadgentbtn li button {
        width: auto;
    }
    .web-sec6 .cta-wraper img {
        margin-top: -75px;
    }
    .offcanvas {
        width: 350px;
    }

    .offcanvas-start-xxl {
        top: 0;
        left: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        transform: translateX(-100%);
    }
    .offcanvas-body {
        .row {
            --bs-gutter-x: 0;
        }
    }
    // .navbar-light {
    //     .customArc{
    //         height: 115px;
    //     }
    //     .wrapper-1 {
    //         padding: 25px 0;
    //         .leadgentbtn{
    //             li{
    //                 &:first-child{
    //                     display: block;
    //                     margin: 20px 0;

    //                 }
    //             }
    //         }
    //     }
    //     .wrapper-2 {
    //         height: auto;
    //         padding: 30px 0;
    //         .leadgentbtn {
    //             display: block;
    //         }
    //     }
    // }
}
@media screen and (max-width: 1366px) {
    .homeSlider {
        .homeslideinner {
            height: calc(100vh - 1px);
        }
    }
    .navbar-light .offcanvas .wrapper-1 .leadgentbtn .list-inline-item:nth-child(1) {
        display: block;
        margin: 10px 0;
    }
    .navbar-light .offcanvas .wrapper-1 .leadgentbtn {
        padding-bottom: 15px;
    }
    .navbar-light .offcanvas .wrapper-2 {
        height: auto;
    }
    #back-to-top {
        display: none;
    }
    .thank-you-sec {
        .textpara-wrape {
            margin: 75px 0;
            padding: 25px;
            h3 {
                font-size: 75px;
                line-height: 85px;
                margin-bottom: 25px;
            }
        }
    }
    .navbar-light .wrapper-2 .navbar-nav li .nav-link.active::after {
        bottom: 0;
    }
    .navbar-light .wrapper-2 .leadgentbtn {
        display: block;
    }
    .home-revised-sec-2 {
        .card .myform-label {
            font-size: 30px;
            padding-bottom: 1em;
            padding-right: 0;
        }
    }
    .home-revised-sec-1 {
        padding: 100px 0 40px;
        h2 {
            font-size: 60px;
            line-height: 60px;
        }
        p {
            font-size: 24px;
            line-height: 30px;
        }
    }

    
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .slider-banner-1 {
        background: no-repeat #549e64 url("../img/banner-resized-01.jpg");
    }
    .slider-banner-2 {
        background: no-repeat #8a5ff2 url("../img/banner-resized-02.jpg");
    }
    .slider-banner-3 {
        background: no-repeat#fff url("../img/banner-resized-03.jpg");
    }
    .slider .textarea .main-title {
        line-height: 50px;
        font-size: 40px;
    }
    .slider .textarea p {
        line-height: 25px;
        font-size: 20px;
        width: 70%;
    }
    .thank-you-sec {
        .textpara-wrape {
            margin: 75px 0;
            padding: 25px;
            h3 {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 25px;
            }
            p {
                font-size: 18px;
                line-height: 24px;
                br {
                    display: none;
                }
            }
            .footet {
                .footer-text {
                    font-size: 34px;
                    line-height: 44px;
                }
            }
        }
    }
    .leadgentbtn li button {
        font-size: 12px;
        padding: 10px 20px;
    }
    .navbar-light .navbar-nav li.nav-item:not(:last-child) {
        margin-right: 0.8rem;
    }
    .navbar-light .navbar-nav a.nav-link {
        font-size: 14px;
    }
    .navbar-light .wrapper-2 .navbar-nav li .nav-link.active::after {
        bottom: 0;
    }
    .slider {
        img.position-absolute {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .master-wrap {
        padding: 0 50px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-body .package-addon {
        font-size: 10px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-body .package-contact-num {
        font-size: 14px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header {
        padding: 24px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(2) {
        font-size: 28px;
        line-height: 36px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(1),
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(3) {
        font-size: 16px;
    }
    .sec6 .s-item legend {
        margin-left: 20px;
        font-size: 18px;
    }
    .sec6 .s-item .d-flex .flex-grow-1 {
        font-size: 14px;
    }
    .web-sec2 {
        .category {
            h2,
            .h2 {
                font-size: 1.5rem;
            }
            .nav-link {
                padding: 1em;
            }
            .tab-content {
                .card {
                    .card-body {
                        padding: 1em 2em;
                    }
                }
            }
        }
    }
    .web-sec6 {
        margin-top: 150px;
        .cta-wraper img {
            margin-top: -85px;
        }
    }
    .E-commerce-sec8 {
        margin: 50px 0 0;
    }
    .E-commerce-sec9.web-sec6 {
        margin-top: 50px;
    }
    .about-us-sec-2 {
        height: 60em;
        margin-bottom: 3.125em;
        &.marketing-process-sec {
            &.culture-innovation {
                .tab-sec {
                    margin-top: 42px;
                    padding-left: calc(var(--bs-gutter-x) * 0.5);
                    .nav-link {
                        border: 2px solid #fff;
                        border-radius: 0;
                        margin: 0.3em 0;
                        padding: 0.5rem 1rem;
                        color: #fff;
                        position: static;
                        height: auto;
                        width: 50%;
                        @mixin span {
                            float: unset;
                            margin: 0;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            br {
                                display: none;
                            }
                        }
                        &:nth-child(1) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(2) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(3) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(4) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(5) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(6) {
                            span {
                                @include span;
                            }
                        }
                    }
                }
                .tab-content {
                    margin-top: 42px;
                }
            }
        }
    }
    .packages-sec {
        background-size: auto;
        .extra-space {
            margin-top: 50px;
            .master-heading {
                .main-heading {
                    &.tag-line {
                        font-size: 34px;
                        line-height: 44px;
                    }
                }
            }
            .card {
                .card-body {
                    .card-header {
                        .combo_pack_doller {
                            p {
                                font-size: 34px;
                                line-height: 44px;
                            }
                        }
                    }
                    .card-footer {
                        text-align: center;
                        .animated-btn {
                            margin-top: 10px;
                            .btn {
                                padding: 0.55em 3em;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-sec {
        margin: 50px 0 0;
        .master-heading {
            .sub-heading {
                font-size: 13px;
                line-height: 22px;
            }
            .main-heading {
                font-size: 52px;
                line-height: 62px;
            }
        }
    }
    .contact-us-sec {
        .cotnact-form-sec {
            padding: 15px 5px;
        }
    }
    .contact-us-sec-2 {
        .card {
            padding: 40px 40px 40px 40px;
            &.elementor-widget-container {
                padding: 95px 40px 84px 40px;
            }
        }
    }
    .inner-banner {
        min-height: auto;
    }
    .bottom {
        .condition {
            margin-right: 0;
        }
    }
    .modal {
        &.modal-right {
            .modal-content {
                .modal-body {
                    padding: 20px;
                    overflow-y: scroll;
                    .master-heading {
                        .main-heading {
                            font-size: 2rem;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
    .home-revised-sec-2 {
        .card .myform-label {
            font-size: 28px;
            line-height: 38px;
        }
    }
    .home-revised-sec-1 {
        padding: 100px 0 30px;
        h2 {
            font-size: 60px;
        }
        p {
            font-size: 22px;
            line-height: 30px;
        }
    }
}
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .sec1.bgGrayTouch .contact-btn-animated .animated-btn{
        display: block;
        }
    .services-tab .tab-content .card .card-body p {
        width: 100%;
    }
    .slider-banner-1 {
        background: #549e64;
    }
    .slider-banner-2 {
        background: #8a5ff2;
    }
    .slider-banner-3 {
        background: no-repeat center/cover #fff url("../img/banner-bg.png");
    }
    .thank-you-sec {
        .textpara-wrape {
            margin: 75px 0;
            padding: 25px;
            h3 {
                font-size: 75px;
                line-height: 85px;
                margin-bottom: 25px;
            }
        }
    }
    .navbar-light .navbar-nav li.nav-item:not(:last-child) {
        margin-right: 0;
    }
    .leadgentbtn li button {
        font-size: inherit;
        padding: 10px 25px;
    }
    .slider {
        img.position-absolute {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        .textarea {
            .main-title {
                line-height: 65px;
                font-size: 55px;
            }
            p {
                line-height: 26px;
                font-size: 18px;
            }
        }
    }
    .textpara .master-heading {
        margin-bottom: 40px;
    }
    .master-wrap {
        padding: 0;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-body .package-addon {
        font-size: 12px;
    }
    .sec5 .animated-area {
        margin: 7rem 0;
    }
    .sec7 .testimonial .slick-slide.slick-current.slick-active .card {
        margin-right: 15px;
        margin-left: 0;
    }
    .sec7 .testimonial .slick-slide.slick-active .card {
        margin-right: 0;
        margin-left: 15px;
    }
    .sec8 {
        margin: 50px 0;
    }

    .offcanvas-start-lg {
        top: 0;
        left: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        transform: translateX(-100%);
    }
    .navbar-light .navbar-nav li.nav-item.animated-btn {
        margin: 15px 0;
        text-align: center;
    }
    .navbar-light .wrapper-2 .navbar-nav li .nav-link.active::after {
        bottom: 0;
    }
    .inner-banner {
        padding: 1em;
        min-height: auto;
        .header-text {
            h1,
            .h1 {
                font-size: 2.375rem;
                br {
                    display: none;
                }
            }
        }
    }
    .web-sec2 .category .tab-content {
        margin-top: 50px;
        .card {
            .card-body {
                padding: 3em;
            }
        }
    }
    .web-sec5 .card.bg-transparent.second::before {
        border: 0;
    }
    .web-sec6,
    .E-commerce-sec9.web-sec6 {
        margin-top: 100px;
        .cta-wraper {
            .cta-para {
                h1 {
                    font-size: 1.5em;
                }
            }
            img {
                margin-top: -60px;
            }
        }
    }
    #slider-in-laptop {
        height: 380px;
        .rsOverflow {
            height: 320px;
        }
    }
    .sec5.E-commerce-sec4 .animated-area .item-animate h5 {
        font-size: 15px;
        line-height: 22px;
    }
    .about-us-sec-2 {
        height: 60em;
        margin-bottom: 3.125em;
        &.marketing-process-sec {
            &.culture-innovation {
                .tab-sec {
                    margin-top: 42px;
                    padding-left: calc(var(--bs-gutter-x) * 0.5);
                    .nav-link {
                        border: 2px solid #fff;
                        border-radius: 0;
                        margin: 0.3em 0;
                        padding: 0.5rem 1rem;
                        color: #fff;
                        position: static;
                        height: auto;
                        width: 50%;
                        @mixin span {
                            float: unset;
                            margin: 0;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            br {
                                display: none;
                            }
                        }
                        &:nth-child(1) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(2) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(3) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(4) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(5) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(6) {
                            span {
                                @include span;
                            }
                        }
                    }
                }
                .tab-content {
                    margin-top: 42px;
                    .tb-text {
                        .title {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact-detail-sec {
        background-position: left;
        background-size: cover;
    }
    .packages-sec {
        background-position: center;
        background-size: auto;
        .extra-space {
            margin-top: 50px;
            .master-heading {
                .main-heading {
                    &.tag-line {
                        font-size: 34px;
                        line-height: 44px;
                    }
                }
            }
            .card {
                .card-body {
                    .card-header {
                        .combo_pack_doller {
                            p {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                    }
                    .card-footer {
                        text-align: center;
                        .animated-btn {
                            margin-top: 10px;
                            .btn {
                                padding: 0.55em 3em;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-sec {
        margin: 50px 0 0;
        .master-heading {
            .sub-heading {
                font-size: 13px;
                line-height: 22px;
            }
            .main-heading {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
    .portfolio-filter-sec {
        .button-group {
            .btn {
                // width: auto;
                margin: 5px 0;
            }
        }
    }
    .inner-banner {
        min-height: auto;
    }
    .modal {
        &.modal-right {
            .modal-content {
                .btn-close {
                    padding: 0 20px;
                    width: 1em;
                    background-size: 25px;
                }
                .modal-body {
                    .master-heading {
                        .main-heading {
                            font-size: 1.5rem;
                        }
                        .sub-heading {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
    .sec1 .card-box .card-header p {
        font-size: 32px;
        padding-right: 20px;
    }
    .discount-form {
        .form_upper {
            h1 {
                font-size: 1.2rem;
            }
            h4 {
                font-size: 16px;
            }
        }
    }
    .home-revised-sec-1 {
        padding: 80px 0 30px;
        h2 {
            font-size: 40px;
            line-height: 50px;
        }
        p {
            font-size: 20px;
            line-height: 26px;
        }
    }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 575.98px) {
    .sec1.bgGrayTouch .contact-btn-animated {
        text-align: center;
        margin-bottom: 50px;
    }
    .sec1.bgGrayTouch .contact-btn-animated .animated-btn {
        width: 100%;
    }
    .packages-sec nav .nav-tabs .nav-link {
        width: 100%;
        border-color: #dee2e6 #dee2e6 #6405d3;
    }
    .thank-you-sec {
        .textpara-wrape {
            margin: 75px 0;
            padding: 25px;
            h3 {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 25px;
            }
            p {
                font-size: 18px;
                line-height: 24px;
                br {
                    display: none;
                }
            }
            .footet {
                .footer-text {
                    font-size: 34px;
                    line-height: 44px;
                }
            }
        }
    }
    .sticky-bar {
        ul.header-socials {
            li.list-inline-item:nth-child(2) {
                margin-right: 0;
            }
        }
    }
    .slider {
        .textarea {
            .main-title {
                line-height: 29px;
                font-size: 24px;
            }
            p {
                br {
                    display: none;
                }
                line-height: 26px;
                font-size: 18px;
            }
            .bannerSlider {
                img {
                    display: none;
                }
            }
        }
        img.position-absolute {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    @mixin master-heading {
        .main-heading {
            font-size: 24px;
            line-height: 34px;
        }
        .sub-heading {
            font-size: 13px;
            line-height: 22px;
        }
    }
    .master-heading {
        @include master-heading;
    }
    .about-us-sec-2 {
        .master-heading {
            @include master-heading;
        }
    }
    .textpara {
        .master-heading {
            margin-bottom: 0;
        }
    }
    .master-wrap {
        padding: 0;
    }
    .sec2 .tab-content#myTabContent .slick-prev,
    .sec2 .tab-content#myTabContent .slick-next {
        top: -25px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header {
        padding: 25px 15px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(2) {
        font-size: 28px;
        line-height: 36px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(1),
    .sec2 .tab-content .multiple-items .slick-slide .card .card-header .package-header ul li:nth-child(3) {
        font-size: 16px;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-body .package-addon {
        font-size: 12px;
        width: 100%;
    }
    .sec2 .tab-content .multiple-items .slick-slide .card .card-body .package-contact-num {
        font-size: 14px;
    }
    .accordion-button,
    .accordion-body {
        padding: 1rem;
    }
    .sec3 .img-arrangement img:nth-child(2) {
        position: absolute;
        top: 20px;
        left: 25px;
        max-width: 35%;
    }
    .sec5 .animated-area .master-heading .main-heading {
        font-size: 36px;
    }
    .sec5 .animated-area {
        margin: 5rem 0;
    }
    .sec6 .s-item legend {
        margin-left: 20px;
        font-size: 16px;
    }
    .sec7 .testimonial .card {
        margin-right: 0;
    }
    .sec8 {
        margin: 50px 0;
    }
    .footer {
        padding: 50px 0 50px;
    }
    .sec6 .s-item .d-flex .flex-grow-1 {
        font-size: 14px;
    }

    .inner-banner {
        padding: 1em;
        min-height: auto;
        .header-text {
            h1,
            .h1 {
                font-size: 2rem;
                br {
                    display: none;
                }
            }
            p {
                font-size: 1rem;
            }
        }
    }

    .web-design-banner .discount-form .form_upper h3,
    .web-design-banner .discount-form .form_upper .h3 {
        padding: 2em 1em 1em;
    }
    .web-sec2 .category .tab-content .card .card-body {
        padding: 2em;
    }
    .web-sec2 .category .tab-content .card ul {
        text-align: center;
    }
    .web-sec2 .category .tab-content .card ul .list-inline-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 25px;
    }
    .web-sec5 .textpara {
        margin-bottom: 40px;
    }
    .web-sec6 {
        margin-top: 0;
        img {
            height: 180px;
        }
        .cta-wraper {
            .cta-para {
                padding: 2em;
            }
            img {
                display: none;
            }
        }
    }
    #slider-in-laptop {
        height: 240px;
        .rsOverflow {
            height: 240px;
        }
    }
    .E-commerce-sec8 {
        margin: 50px 0 0;
    }
    .E-commerce-sec9.web-sec6 {
        margin-top: 50px;
    }
    .about-us-sec-2 {
        height: 60em;
        margin-bottom: 3.125em;
        &.marketing-process-sec {
            &.culture-innovation {
                .tab-sec {
                    margin-top: 42px;
                    padding-left: calc(var(--bs-gutter-x) * 0.5);
                    .nav-link {
                        border: 2px solid #fff;
                        border-radius: 0;
                        margin: 0.3em 0;
                        padding: 0.5rem 1rem;
                        color: #fff;
                        position: static;
                        height: auto;
                        width: auto;
                        @mixin span {
                            float: unset;
                            margin: 0;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            br {
                                display: none;
                            }
                        }
                        &:nth-child(1) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(2) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(3) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(4) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(5) {
                            span {
                                @include span;
                            }
                        }
                        &:nth-child(6) {
                            span {
                                @include span;
                            }
                        }
                    }
                }
                .tab-content {
                    margin-top: 42px;
                    .tb-text {
                        .title {
                            font-size: 24px;
                            line-height: 34px;
                            br {
                                display: none;
                            }
                        }
                        .description {
                            font-size: 13px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
    .contact-detail-sec {
        background-position: left;
        background-size: cover;
    }
    .packages-sec {
        background-position: center;
        background-size: auto;
        .extra-space {
            margin-top: 50px;
            .master-heading {
                .main-heading {
                    &.tag-line {
                        font-size: 24px;
                        line-height: 34px;
                    }
                }
            }
            .card {
                .card-body {
                    .card-header {
                        .combo_pack_doller {
                            p {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                    }
                    .card-footer {
                        text-align: center;
                        .animated-btn {
                            margin-top: 10px;
                            .btn {
                                padding: 0.55em 3em;
                            }
                        }
                    }
                }
            }
        }
    }
    .portfolio-sec {
        margin: 50px 0 0;
        .master-heading {
            .sub-heading {
                font-size: 13px;
                line-height: 22px;
            }
            .main-heading {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
    .portfolio-filter-sec {
        .button-group {
            margin-bottom: 50px;
            .btn {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
    .contact-us-sec {
        .cotnact-form-sec {
            padding: 15px 5px;
        }
    }
    .modal {
        &.modal-right {
            &.fade {
                .modal-dialog {
                    width: 100%;
                }
            }
            .modal-content {
                .modal-body {
                    .master-heading {
                        .main-heading {
                            font-size: 2rem;
                            line-height: 35px;
                        }
                        .sub-heading {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .bottom {
        .condition {
            float: none;
            text-align: center;
            margin: 15px 0;
        }
    }
    // .slider-banner-3 {
    //     background: url('../img/sliderBanner.png') no-repeat center/cover;
    // }
    .homeSlider .slider-margin {
        margin: 0;
    }
    .sec1 .card-box .card-header p {
        font-size: 24px;
    }
    .sec1 .rightSideBox .card-box {
        margin: 55px 0 55px;
    }
    .projectSliderWrapper {
        margin-left: 0;
    }
    .projectSliderWrapper .swiper-slide .card .card-body ul li::before {
        left: 20px;
    }
    .projectSliderWrapper .swiper-slide .card .card-body .card-text {
        height: 75px;
    }
    .navbar-light {
        .customArc {
            height: 116px;
        }
        .wrapper-1 {
            padding: 25px 0;
            .leadgentbtn {
                li {
                    &:first-child {
                        display: block;
                        margin: 20px 0;
                    }
                }
            }
        }
        .wrapper-2 {
            .leadgentbtn {
                display: block;
            }
            .navbar-nav {
                li {
                    .nav-link {
                        &.active::after {
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .home-revised-sec-2 {
        .card {
            .myform-label {
                font-size: 17px;
                line-height: 28px;
                &.heightControl {
                    height: 125px;
                }
            }
            label {
                &.custom-control-label {
                    font-size: 15px;
                    line-height: 30px;
                }
            }
        }
    }
    .home-revised-sec-1 {
        padding: 70px 0 20px;
        h2 {
            font-size: 30px;
            line-height: 36px;
        }
        p {
            &.lead {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}
#form1 input#other, #form2 input#other ,#form3 input#other, #form4 input#other, #form5 input#other {
    width: 100%;
}
#form1 label, #form2 label, #form3 label, #form4 label, #form5 label {
    width: 100%;
    line-height: 1.125;
    margin-bottom: 0.5rem;
}
.web-design-modal #form6 .form-control {
    width: 96%;
}